import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../Header/Footer';
import Carousel from '../Home/Carousel/Carousel'; import AreaInformation from './AreaInformation';
;

function SingleListing() {

    const initialFormReview = {
        content: "",
        rating: ""
    }
    const initialNoteFormReview = {
        note: ""
    }
    const [formData, setFormData] = useState({})
    const [formReviewData, setFormReviewData] = useState(initialFormReview)
    const [formNoteData, setFormNoteData] = useState(initialNoteFormReview)
    const { slug } = useParams()
    const [property, setProperty] = useState([])
    const [propertyList, setPropertyList] = useState([])
    const [wishlist, setWishlist] = useState([])
    const [userList, setUserList] = useState([])
    const [photo, setPhoto] = useState([])
    const [photoList, setPhotoList] = useState([])
    const [rating, setRating] = useState(4)
    const [user, setUser] = useState({})
    const [postBy, setPostBy] = useState({})
    const [city, setCity] = useState({})
    const [listingData, setListingData] = useState([])
    const [showFullDescription, setShowFullDescription] = useState(false);
    const maxDescriptionLength = 300; // Adjust this limit as needed
    const [showText, setShowText] = useState(false);
    const [privateNote, setPrivateNote] = useState(null);
    const [isReviewed, setIsReviewed] = useState(false);

    console.log("privateNote =>", privateNote)

    const toggleDescription = () => {
        setShowFullDescription((prev) => !prev);
    };

    // images = [...photo]

    // console.log('property =>', property)

    const apiUrl = process.env.REACT_APP_URL;

    const apiKey = process.env.REACT_APP_API_KEY;

    const Token = localStorage.getItem("Token");

    // Set the listing date using an ISO 8601 string
    const listingDate = new Date(property.createdAt);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in time
    const timeDifference = currentDate - listingDate;

    // Calculate the difference in days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));


    let oneSelectedListingDetails

    if (property?.listingDetails) {
        const listingDetailsArr = Object.keys(property?.listingDetails)
        oneSelectedListingDetails = listingDetailsArr.filter((key) => (property.listingDetails[key].length === 1))
    }

    function formatDate(isoDate) {
        const date = new Date(isoDate);

        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    }

    const openWhatsApp = (phoneNumber) => {
        if (phoneNumber) {
            // Remove any non-digit characters
            let sanitizedNumber = phoneNumber.replace(/\D/g, "");

            // Add Venezuela country code (58) if it's not already included
            if (!sanitizedNumber.startsWith("58")) {
                sanitizedNumber = `58${sanitizedNumber}`;
            }

            // Construct the WhatsApp URL
            const whatsappUrl = `https://wa.me/${sanitizedNumber}`;

            // Open the WhatsApp link in a new tab
            window.open(whatsappUrl, "_blank");
        } else {
            console.error("Phone number is not available.");
        }
    };

    useEffect(() => {
        fetch(`${apiUrl}/faq/getByCategory/Listing`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    console.log('ListingData =>', data.data)
                    setListingData(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [])

    useEffect(() => {
        async function getUser() {
            const url = `${apiUrl}/employee/verify-customer`;

            let response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setUser(response.data)

                const propertyId = slug.split('-').pop()

                console.log("propertyId =>", propertyId)

                console.log("data in verify =>", response.data)

                response.data.PropertyPrivateNote.map((item) => {
                    if (item.propertyId == propertyId) {
                        setPrivateNote(item);
                    }
                })

            }
        }
        getUser();
    }, [apiUrl, Token]);


    useEffect(() => {

        fetch(`${apiUrl}/property/propertyBySlug/${slug}`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setProperty(data.data)
                    if (data.data?.Customer?.userType === 0) {
                        setPostBy({
                            name: data.data.Customer.name,
                            id: data.data.Customer.id,
                            email: data.data.Customer.email,
                            userDetails: data.data.Customer.userDetails[0]
                        })
                    } else if (data.data?.Customer?.userType === 1) {
                        setPostBy({
                            name: data.data.Customer.name,
                            id: data.data.Customer.id,
                            email: data.data.Customer.email,
                            userDetails: data.data.Customer.agentDetails[0]
                        })
                    } else if (data.data?.Customer?.userType === 2) {
                        setPostBy({
                            name: data.data.Customer.name,
                            id: data.data.Customer.id,
                            email: data.data.Customer.email,
                            userDetails: data.data.Customer.agencyDetails[0]
                        })
                    }
                    console.log("data =>", data.data)

                    const filteredPropertyReview = data.data.propertyReview.filter((data) => user.id === data.customerId)
                    console.log("filteredPropertyReview =>", filteredPropertyReview)
                    console.log("isReviewed =>", isReviewed)
                    console.log("user.id =>", user.id)
                    if (filteredPropertyReview.length > 0) {
                        setIsReviewed(true)
                    }

                    let count = [];
                    for (let i = 0; i < 5; i++) {
                        if (data.data[`photo${i + 1}`]) {
                            count.push(data.data[`photo${i + 1}`]);
                        }
                    }
                    console.log(count, 'count=======');
                    setPhoto(count);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [])

    useEffect(() => {
        fetch(`${apiUrl}/property/property?limit=5`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setPropertyList(data.data)
                    console.log(data.data)
                    let count = []
                    for (let j = 0; j < data.data.length; j++) {
                        let temp = 0;
                        for (let i = 0; i < 5; i++) {
                            if (data.data[j][`photo${i + 1}`]) {
                                temp++;
                            }
                        }
                        count = [...count, temp]
                    }
                    console.log(count, 'count=======')
                    setPhotoList(count)
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [])

    useEffect(() => {
        async function getCity() {
            const url = `${apiUrl}/property/property?customerId=${property.customerId}`;

            let response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setUserList(response.data)
            }
        }
        if (property?.customerId) {
            getCity()
        }
    }, [apiKey, apiUrl, property]);


    useEffect(() => {
        async function getCity() {
            const url = `${apiUrl}/employee/getCity/${property.city}`;


            let response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setCity(response.data)
            }
        }
        if (property?.city) {
            getCity()
        }
    }, [property, apiUrl, apiKey]);


    // A function to handle the change of the input fields
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleReviewChange = (e) => {
        const { name, value } = e.target

        console.log({ name, value })

        setFormReviewData((prev) => ({ ...prev, ['rating']: rating }));

        setFormReviewData((prev) => ({ ...prev, [name]: value }));

    };

    const handleNoteChange = (e) => {
        const { name, value } = e.target

        console.log({ name, value })

        setFormNoteData((prev) => ({ ...prev, [name]: value }));

    }




    // A function to handle the submit of the property form
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();
            formData.propertyId = slug.split('-').pop()
            formData.userId = user.id
            if (!formData.userId) {
                toast.error("Login First")
                return
            }
            for (const key in formData) {
                if (formData[key] !== null) {
                    formDataToSend.append(key, formData[key]);
                }
            }

            const response = await fetch(`${apiUrl}/enquiry/add`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                body: formDataToSend,
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            // navigate("/Master");
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleReviewSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();
            formReviewData.propertyId = slug.split('-').pop()
            formReviewData.customerId = user.id
            if (!formReviewData.customerId) {
                toast.error("Login First")
                return
            }
            for (const key in formReviewData) {
                if (formReviewData[key] !== null) {
                    formDataToSend.append(key, formReviewData[key]);
                }
            }

            const response = await fetch(`${apiUrl}/review/addReview`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                body: formDataToSend,
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleNoteSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();
            formNoteData.propertyId = slug.split('-').pop()
            formNoteData.customerId = user.id
            if (!formNoteData.customerId) {
                toast.error("Login First")
                return
            }
            for (const key in formNoteData) {
                if (formNoteData[key] !== null) {
                    formDataToSend.append(key, formNoteData[key]);
                }
            }

            const response = await fetch(`${apiUrl}/review/addPrivateNote`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                body: formDataToSend,
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        } catch (error) {
            toast.error(error.message);
        }
    };

    // A function to handle the submit of the property form
    const handleWishlist = async (e, id, userId) => {
        e.preventDefault();
        e.stopPropagation();
        if (!userId) {
            toast.error("Login First")
            return
        }

        try {
            const response = await fetch(`${apiUrl}/property/addToWishlist/${userId}-${id}`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                }
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2500)
        } catch (error) {
            toast.error(error.message);
        }
    };

    // A function to handle the submit of the property form
    const handleCompare = async (e, id, userId) => {
        e.preventDefault();
        e.stopPropagation();
        e.stopPropagation();
        if (!userId) {
            toast.error("Login First")
            return
        }

        try {

            const response = await fetch(`${apiUrl}/property/addToCompare/${userId}-${id}`, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                }
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2500)
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        async function getWishlist() {
            const url = `${apiUrl}/property/getWishlist/${user.id}`;


            let response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setWishlist(response.wishlistProperty)
                if (Array.isArray(response.wishlistProperty)) {
                    console.log("wishlistProperty is an array");
                } else {
                    console.log("wishlistProperty is not an array");
                }
            }
        }
        if (user?.id) {
            getWishlist()
        }

    }, [user, apiUrl, Token]);



    // const settings = {
    //     focusOnSelect: true,
    //     dots: true,
    //     infinite: true,
    //     lazyLoad: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 initialSlide: 1
    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1
    //             }
    //         }
    //     ]
    // };



    return (
        <div>
            {/*=============== basic  ===============*/}
            {/* <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content /> */}
            {/*=============== css  ===============*/}
            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />
            {/*=============== favicons ===============*/}
            {/* <link rel="shortcut icon" href="images/favicon.ico" /> */}
            {/*loader*/}
            <div className="loader-wrap">
                <div className="loader-inner">
                    <svg>
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur
                                    in="SourceGraphic"
                                    stdDeviation={2}
                                    result="blur"
                                />
                                <feColorMatrix
                                    in="blur"
                                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                                    result="gooey"
                                />
                                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
            {/*loader end*/}
            {/* main */}
            <div id="main">
                {/* wrapper  */}

                <div id="wrapper">
                    {/* content */}
                    <div className="content">


                        <div className='carousel slide' id="propertyCarousel" data-bs-ride="carousel">
                            <div className='carousel-inner color-bg'>

                                {/* <Carousel photo={photo} /> */}


                                {photo.map((data, index) => (
                                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} style={{ backgroundColor: 'black' }}>
                                        <img src={`${apiUrl}/uploads/${data}`} alt="property" height={'500vh'} />
                                    </div>
                                ))}


                            </div>

                            <button class="carousel-control-prev" type="button" data-bs-target="#propertyCarousel" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#propertyCarousel" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>

                        </div>

                        <div className="breadcrumbs fw-breadcrumbs smpar fl-wrap">
                            <div className="container">
                                <div className="breadcrumbs-list">
                                    <a href="#">Home</a>
                                    <a href="#">Listings</a>
                                    <a href={`/city-${city.name}-${city.id}`}>{city.name}</a>
                                    <span>{property.name}</span>
                                </div>
                                <div className="show-more-snopt smact">
                                    <i className="fa-solid  fa-ellipsis-v" />
                                </div>
                                <div className="show-more-snopt-tooltip">
                                    {/* <a href="#sec15" className="custom-scroll-link">
                                        {" "}
                                        <i className="fa-solid fa-comment-alt" /> Write a review
                                    </a> */}
                                    <a href="#">
                                        {" "}
                                        <i className="fa-solid fa-exclamation-triangle" /> Report{" "}
                                    </a>
                                </div>

                                <a
                                    className="compare-top-btn tolt"
                                    data-microtip-position="bottom"
                                    data-tooltip="Compare"
                                    href="#2"
                                    onClick={(e) => {
                                        handleCompare(e, property.id, user.id)
                                    }}
                                >
                                    <i className="fa-solid fa-random" />
                                </a>
                                <div
                                    className="like-btn"
                                    onClick={(e) => {
                                        handleWishlist(e, property.id, user.id)
                                    }}
                                >
                                    <i className={`fa-solid fa-heart ${wishlist?.find((item) => item === property.id) ? "text-danger" : ""}`} /> Save
                                </div>
                                <a
                                    className="print-btn tolt"
                                    href="#1"
                                    data-microtip-position="bottom"
                                    data-tooltip="Print"
                                    style={{ visibility: 'hidden' }}
                                >
                                    <i className="fa-solid fa-print" />
                                </a>
                            </div>
                        </div>
                        {/* <Slider {...settings}>
                            <div>
                                <div className="slick-slide-item">
                                    <div className="box-item">
                                        <img src="https://hauzzi.webkype.net/images/all/5.jpg" alt="property" />
                                        <a href="https://hauzzi.webkype.net/images/all/5.jpg" className="gal-link popup-image">
                                            <i className="fa-solid  fa-search" />
                                        </a>
                                        <div className="show-info">
                                            <span>
                                                <i className="fas fa-info" />
                                            </span>
                                            <div className="tooltip-info">
                                                <h5>Room Details</h5>
                                                <p>
                                                    Sed non nisi viverra, porttitor sem nec, vestibulum
                                                    justo tortor ornare turpis faucibus
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                            <div className="slick-slide-item">
                                    <div className="box-item">
                                        <img src="https://hauzzi.webkype.net/images/all/5.jpg" alt="property" />
                                        <a href="https://hauzzi.webkype.net/images/all/5.jpg" className="gal-link popup-image">
                                            <i className="fa-solid  fa-search" />
                                        </a>
                                        <div className="show-info">
                                            <span>
                                                <i className="fas fa-info" />
                                            </span>
                                            <div className="tooltip-info">
                                                <h5>Room Details</h5>
                                                <p>
                                                    Sed non nisi viverra, porttitor sem nec, vestibulum
                                                    justo tortor ornare turpis faucibus
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                            <div className="slick-slide-item">
                                    <div className="box-item">
                                        <img src="https://hauzzi.webkype.net/images/all/5.jpg" alt="property" />
                                        <a href="https://hauzzi.webkype.net/images/all/5.jpg" className="gal-link popup-image">
                                            <i className="fa-solid  fa-search" />
                                        </a>
                                        <div className="show-info">
                                            <span>
                                                <i className="fas fa-info" />
                                            </span>
                                            <div className="tooltip-info">
                                                <h5>Room Details</h5>
                                                <p>
                                                    Sed non nisi viverra, porttitor sem nec, vestibulum
                                                    justo tortor ornare turpis faucibus
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                            <div className="slick-slide-item">
                                    <div className="box-item">
                                        <img src="https://hauzzi.webkype.net/images/all/5.jpg" alt="property" />
                                        <a href="https://hauzzi.webkype.net/images/all/5.jpg" className="gal-link popup-image">
                                            <i className="fa-solid  fa-search" />
                                        </a>
                                        <div className="show-info">
                                            <span>
                                                <i className="fas fa-info" />
                                            </span>
                                            <div className="tooltip-info">
                                                <h5>Room Details</h5>
                                                <p>
                                                    Sed non nisi viverra, porttitor sem nec, vestibulum
                                                    justo tortor ornare turpis faucibus
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                            <div className="slick-slide-item">
                                    <div className="box-item">
                                        <img src="https://hauzzi.webkype.net/images/all/5.jpg" alt="property" />
                                        <a href="https://hauzzi.webkype.net/images/all/5.jpg" className="gal-link popup-image">
                                            <i className="fa-solid  fa-search" />
                                        </a>
                                        <div className="show-info">
                                            <span>
                                                <i className="fas fa-info" />
                                            </span>
                                            <div className="tooltip-info">
                                                <h5>Room Details</h5>
                                                <p>
                                                    Sed non nisi viverra, porttitor sem nec, vestibulum
                                                    justo tortor ornare turpis faucibus
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                            <div className="slick-slide-item">
                                    <div className="box-item">
                                        <img src="https://hauzzi.webkype.net/images/all/5.jpg" alt="property" />
                                        <a href="https://hauzzi.webkype.net/images/all/5.jpg" className="gal-link popup-image">
                                            <i className="fa-solid  fa-search" />
                                        </a>
                                        <div className="show-info">
                                            <span>
                                                <i className="fas fa-info" />
                                            </span>
                                            <div className="tooltip-info">
                                                <h5>Room Details</h5>
                                                <p>
                                                    Sed non nisi viverra, porttitor sem nec, vestibulum
                                                    justo tortor ornare turpis faucibus
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider> */}
                        <div className="gray-bg small-padding fl-wrap">
                            <div className="container">
                                <div className="row">
                                    {/*  listing-single content */}
                                    <div className="col-md-8">
                                        <div className="list-single-main-wrapper fl-wrap">
                                            {/*  scroll-nav-wrap */}
                                            <div className="scroll-nav-wrap">
                                                <nav className="scroll-nav scroll-init fixed-column_menu-init">
                                                    <ul className="no-list-style">
                                                        <li>
                                                            <a className="act-scrlink" href="#sec1">
                                                                <i className="fa-solid  fa-image" />
                                                            </a>
                                                            <span>Gallery</span>
                                                        </li>
                                                        <li>
                                                            <a href="#sec2">
                                                                <i className="fa-solid  fa-info" />{" "}
                                                            </a>
                                                            <span>Details</span>
                                                        </li>
                                                        <li>
                                                            <a href="#sec3">
                                                                <i className="fa-solid  fa-bahai" />
                                                            </a>
                                                            <span>Features</span>
                                                        </li>
                                                        <li>
                                                            <a href="#sec4">
                                                                <i className="fa-solid  fa-video" />
                                                            </a>
                                                            <span>Video</span>
                                                        </li>
                                                        {/* <li>
                                                            <a href="#sec5">
                                                                <i className="fa-solid  fa-map-pin" />
                                                            </a>
                                                            <span>Location</span>
                                                        </li> */}
                                                        {/* <li>
                                                            <a href="#sec6">
                                                                <i className="fa-solid  fa-comment-dots" />
                                                            </a>
                                                            <span>Reviews</span>
                                                        </li> */}
                                                    </ul>
                                                    <div className="progress-indicator">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="-1 -1 34 34"
                                                        >
                                                            <circle
                                                                cx={16}
                                                                cy={16}
                                                                r="15.9155"
                                                                className="progress-bar__background"
                                                            />
                                                            <circle
                                                                cx={16}
                                                                cy={16}
                                                                r="15.9155"
                                                                className="progress-bar__progress 
                                                        js-progress-bar"
                                                            />
                                                        </svg>
                                                    </div>
                                                </nav>
                                            </div>
                                            {/*  scroll-nav-wrap end*/}
                                            {/*  list-single-opt_header*/}
                                            <div className="list-single-opt_header fl-wrap">
                                                <ul className="list-single-opt_header_cat">
                                                    {property.purpose != 'wantToSell' && <li>
                                                        <a href="#" className="cat-opt color-bg">
                                                            Rent
                                                        </a>
                                                    </li>}
                                                    {property.purpose != 'wantToRent' && <li>
                                                        <a href="#" className="cat-opt blue-bg">
                                                            Sale
                                                        </a>
                                                    </li>}
                                                    {/* <li>
                                                        <a href="#" className="cat-opt color-bg">
                                                            {property.category}
                                                        </a>
                                                    </li> */}
                                                    <li>
                                                        <a href="#" className="cat-opt color-bg">
                                                            <i className="fa-solid fa-eye" /> Viewed - {property.views}{" "}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="cat-opt color-bg">
                                                            <i className="fa-solid fa-heart" /> Bookmark - {property.saved}{" "}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="cat-opt color-bg">

                                                            {daysDifference === 0 &&
                                                                `Posted : Today`
                                                            }
                                                            {daysDifference === 1 &&
                                                                `Posted : Yesterday`
                                                            }
                                                            {daysDifference > 1 &&
                                                                `Posted : ${daysDifference} days ago`}
                                                            {/* <span> Date:</span>{new Date(property.createdAt).toDateString()} */}

                                                        </a>
                                                    </li>

                                                </ul>
                                                <div className="share-holder hid-share">
                                                    <a href="#" className="share-btn showshare sfcs">
                                                        {" "}
                                                        <i className="fa-solid fa-share-alt" /> Share{" "}
                                                    </a>
                                                    <div className="share-container  isShare" />
                                                </div>
                                            </div>
                                            {/*  list-single-opt_header end */}
                                            {/*  list-single-header-item*/}
                                            <div
                                                className="list-single-header-item  fl-wrap"
                                                id="sec2"
                                            >
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <h1>
                                                            {property.name}{" "}
                                                            <span
                                                                className="verified-badge tolt"
                                                                data-microtip-position="bottom"
                                                                data-tooltip="Verified"
                                                            >
                                                                <i className="fa-solid fa-check" />
                                                            </span>
                                                        </h1>
                                                        <div className="geodir-category-location fl-wrap">
                                                            <a href="#">
                                                                <i className="fa-solid fa-map-marker-alt" /> {property.address1} {property?.countries?.name}, {property?.states?.name}, {property?.cities?.name}
                                                            </a>
                                                            {/* <div
                                                                className="listing-rating card-popup-rainingvis"
                                                                data-starrating2={4}
                                                            >
                                                                <span className="re_stars-title">Good</span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <a
                                                            className="host-avatar-wrap"
                                                            href="#agent-single"
                                                        >
                                                            <span style={{ fontSize: 'smaller' }}>By {property.postedBy}</span>
                                                            {property?.Customer?.userType === 0 && <img
                                                                src={property.Customer.userDetails[0]?.photo ? `${apiUrl}/uploads/${property.Customer.userDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/avatar/1.jpg"}
                                                                alt="Posted By"
                                                            />}
                                                            {property?.Customer?.userType === 1 && <img
                                                                src={property.Customer.agentDetails[0]?.photo ? `${apiUrl}/uploads/${property.Customer.agentDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/avatar/1.jpg"}
                                                                alt="Posted By"
                                                            />}
                                                            {property?.Customer?.userType === 2 && <img
                                                                src={property.Customer.agencyDetails[0]?.photo ? `${apiUrl}/uploads/${property.Customer.agencyDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/avatar/1.jpg"}
                                                                alt="Posted By"
                                                            />}
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="list-single-facts fl-wrap">
                                                {/* inline-facts */}
                                                <div className="inline-facts-wrap">
                                                    <div className="inline-facts">
                                                        <i className="fa-solid  fa-industry" />
                                                        <h6>Type</h6>
                                                        <span>{property.type}</span>
                                                    </div>
                                                </div>
                                                {/* inline-facts end */}
                                                {/* inline-facts */}
                                                <div className="inline-facts-wrap">
                                                    <div className="inline-facts">
                                                        <i className="fa-solid  fa-home-lg" />
                                                        <h6>Category</h6>
                                                        <span>{property.category}</span>
                                                    </div>
                                                </div>
                                                {/* inline-facts end */}
                                                {/* inline-facts */}
                                                {property.maxPrice !== '0' && <div className="inline-facts-wrap" style={{ padding: '20px 0px' }}>
                                                    <div className="inline-facts">
                                                        <i className="fa-solid  fa-dollar" />
                                                        <h6>Sale Price</h6>
                                                        <div style={{ display: 'grid' }} >
                                                            <span style={{ fontSize: 'small' }}>USD$ {property.maxPrice} </span>
                                                            <span style={{ fontSize: 'small' }}>Bs. {property.maxPrice * 36}</span>
                                                        </div>

                                                    </div>
                                                </div>}
                                                {/* inline-facts end */}
                                                {/* inline-facts */}
                                                {property.maxPrice !== '0' && <div className="inline-facts-wrap">
                                                    <div className="inline-facts">
                                                        {/* <i className="fa-solid  fa-dollar" /> */}
                                                        <i className="fa-solid fa-tent-arrow-left-right" />
                                                        <h6>Property Size</h6>
                                                        <span>{property.maxSize}</span>
                                                    </div>
                                                </div>}
                                                {/* inline-facts end */}
                                                {/* inline-facts */}
                                                {property.rentalPrice !== '0' && <div className="inline-facts-wrap">
                                                    <div className="inline-facts">
                                                        <i className="fa-solid  fa-dollar" />
                                                        <h6>Rent/Month</h6>
                                                        <span>{property.rentalPrice}</span>
                                                    </div>
                                                </div>}
                                                {/* inline-facts end */}
                                                {/* inline-facts */}
                                                {property.rentalPrice !== '0' && <div className="inline-facts-wrap">
                                                    <div className="inline-facts">
                                                        <i className="fa-solid  fa-ruler" />
                                                        <h6>Rented Area (sq ft)</h6>
                                                        <span>{property.propertySize}</span>
                                                    </div>
                                                </div>}
                                                {/* inline-facts end */}
                                                {/* inline-facts  */}
                                                {/* <div className="inline-facts-wrap">
                                                    <div className="inline-facts">
                                                        <i className="fa-solid  fa-users" />
                                                        <h6>Accomodation</h6>
                                                        <span>{parseInt(property?.bedrooms) * 2 || 6} Guest</span>
                                                    </div>
                                                </div> */}
                                                {/* inline-facts end */}
                                                {/* inline-facts */}
                                                {/* <div className="inline-facts-wrap">
                                                    <div className="inline-facts">
                                                        <i className="fa-solid  fa-bed" />
                                                        <h6>Bedrooms</h6>
                                                        <span>{property?.bedrooms || 3} Bedrooms / {parseInt(property?.bedrooms) + 1 || 4} Beds</span>
                                                    </div>
                                                </div> */}
                                                {/* inline-facts end */}
                                                {/* inline-facts */}
                                                {/* <div className="inline-facts-wrap">
                                                    <div className="inline-facts">
                                                        <i className="fa-solid  fa-bath" />
                                                        <h6>Bathrooms</h6>
                                                        <span>{property?.listingDetails?.Bathrooms} Full</span>
                                                    </div>
                                                </div> */}
                                                {/* inline-facts end */}
                                            </div>
                                            <div className="list-single-main-container fl-wrap">
                                                {/* list-single-main-item */}
                                                <div className="list-single-main-item fl-wrap">
                                                    <div className="list-single-main-item-title">
                                                        <h3>About This Listing</h3>
                                                    </div>
                                                    <div className="list-single-main-item_content fl-wrap">
                                                        <p>{showFullDescription
                                                            ? property?.description
                                                            : property?.description?.slice(0, maxDescriptionLength) + "..."}</p>
                                                        {property?.description?.length > maxDescriptionLength && (
                                                            <button onClick={toggleDescription} className="btn float-btn color-bg">
                                                                {showFullDescription ? "Show Less" : "Load More"}
                                                            </button>
                                                        )}
                                                    </div>
                                                    {/* <div className="list-single-main-item_content fl-wrap">
                                                        <p>
                                                            {property.description}{" "}
                                                        </p>
                                                    </div> */}
                                                </div>


                                                {/* list-single-main-item end */}
                                                {/* list-single-main-item */}
                                                <div
                                                    className="list-single-main-item fl-wrap"
                                                    id="sec3"
                                                >
                                                    <div className="list-single-main-item-title">
                                                        <h3>Details</h3>
                                                    </div>
                                                    <div className="list-single-main-item_content fl-wrap">
                                                        <div className="details-list">
                                                            {property.listingDetails && (
                                                                <ul>

                                                                    {oneSelectedListingDetails.map((key) => (
                                                                        <li key={key}>
                                                                            <span>{key}:</span>
                                                                            {property.listingDetails[key]}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* list-single-main-item end */}
                                                {/* list-single-main-item */}
                                                {(property?.propertyDocs?.length > 0) &&
                                                    <div className="box-widget fl-wrap">
                                                        <div className="box-widget-title fl-wrap" style={{ marginLeft: '20px' }}>
                                                            Propertie Documents
                                                        </div>
                                                        <div className="box-widget-content fl-wrap" >
                                                            <div className="bwc_download-list" style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}>
                                                                <div
                                                                    onClick={(e) => {
                                                                        window.open(`${apiUrl}/uploads/${property.propertyDoc}`)
                                                                    }}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <a download>
                                                                        <span>
                                                                            <i className="fa-solid  fa-file-pdf" />
                                                                        </span>
                                                                        Property Presentation
                                                                    </a>
                                                                </div>
                                                                <div
                                                                    onClick={(e) => {
                                                                        window.open(`${apiUrl}/uploads/${property.propertyDoc1}`)
                                                                    }}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <a download>
                                                                        <span>
                                                                            <i className="fa-solid  fa-file-word" />
                                                                        </span>
                                                                        Energetic Certificate
                                                                    </a>
                                                                </div>
                                                                <div
                                                                    onClick={(e) => {
                                                                        window.open(`${apiUrl}/uploads/${property.propertyDoc2}`)
                                                                    }}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <a download>
                                                                        <span>
                                                                            <i className="fa-solid  fa-file-pdf" />
                                                                        </span>
                                                                        Property Plans
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                                {/* <div className="list-single-main-item fl-wrap">
                                                    <div className="list-single-main-item-title">
                                                        <h3>Floor Plans</h3>
                                                    </div>
                                                    <div className="accordion">
                                                        <a className="toggle act-accordion" href="#">
                                                            {" "}
                                                            First Floor Plan <strong>286 sq ft</strong>{" "}
                                                            <span />
                                                        </a>
                                                        <div className="accordion-inner visible">
                                                            <img
                                                                src="https://hauzzi.webkype.net/images/plans/1.jpg"
                                                                alt="property"
                                                            />
                                                            <p>
                                                                Lorem ipsum dolor sit amet, consectetur
                                                                adipiscing elit. Maecenas in pulvinar neque.
                                                                Nulla finibus lobortis pulvinar. Donec a
                                                                consectetur nulla. Nulla posuere sapien vitae
                                                                lectus suscipit, et pulvinar nisi tincidunt. .
                                                            </p>
                                                        </div>
                                                        <a className="toggle" href="#">
                                                            Second Floor Plan <strong>280 sq ft</strong>{" "}
                                                            <span />
                                                        </a>
                                                        <div className="accordion-inner">
                                                            <img
                                                                src="https://hauzzi.webkype.net/images/plans/2.jpg"
                                                                alt="property"
                                                            />
                                                            <p>
                                                                Aliquam erat volutpat. Curabitur convallis
                                                                fringilla diam sed aliquam. Sed tempor iaculis
                                                                massa faucibus feugiat. In fermentum facilisis
                                                                massa, a consequat purus viverra
                                                            </p>
                                                        </div>
                                                        <a className="toggle" href="#">
                                                            {" "}
                                                            Garage Plan <strong>180 sq ft</strong> <span />
                                                        </a>
                                                        <div className="accordion-inner">
                                                            <img
                                                                src="https://hauzzi.webkype.net/images/plans/3.jpg"
                                                                alt="property"
                                                            />
                                                            <p>
                                                                Lorem ipsum dolor sit amet, consectetur
                                                                adipiscing elit. Sed tempor iaculis massa
                                                                faucibus feugiat. In fermentum facilisis massa,
                                                                a consequat purus viverra.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* list-single-main-item end */}
                                                {/* list-single-main-item */}
                                                {property?.youtubeLink &&
                                                    <div className="list-single-main-item fl-wrap" id="sec4">
                                                        <div className="list-single-main-item-title">
                                                            <h3>Video</h3>
                                                        </div>
                                                        <div className="list-single-main-item_content fl-wrap">
                                                            <div className="video-box fl-wrap">
                                                                <iframe
                                                                    title="YouTube Video"
                                                                    width="560"
                                                                    height="315"
                                                                    src={`https://www.youtube.com/embed/${property?.youtubeLink?.split('/').pop()}`}
                                                                    frameBorder="0"
                                                                    allowFullScreen
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {/* list-single-main-item end */}
                                                {/* list-single-main-item */}
                                                {property.listingDetails && (
                                                    <ul>
                                                        {Object.keys(property.listingDetails).map((key) => (
                                                            <li key={key}>
                                                                {property.listingDetails[key].length >= 2 ? (

                                                                    <div className="list-single-main-item fl-wrap">
                                                                        <div className="list-single-main-item-title">
                                                                            <h3>{key}</h3>
                                                                        </div>
                                                                        <div className="list-single-main-item_content fl-wrap">
                                                                            <div className="listing-features ">
                                                                                <ul>
                                                                                    {property.listingDetails[key].map((data) => (<li>
                                                                                        <a href="#">
                                                                                            <FontAwesomeIcon icon="fas fa-check" /> {data}
                                                                                        </a>
                                                                                    </li>))}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                                {/* list-single-main-item end */}
                                                {/* list-single-main-item */}
                                                {/* <div
                                                    className="list-single-main-item fw-lmi fl-wrap"
                                                    id="sec5"
                                                >
                                                    <div className="map-container mapC_vis mapC_vis2">
                                                        <div
                                                            id="singleMap"
                                                            data-latitude="40.7427837"
                                                            data-longitude="-73.11445617675781"
                                                            data-maptitle="Our Location"
                                                            data-infotitle="House in Financial Distric"
                                                            data-infotext="70 Bright St New York, USA"
                                                        />
                                                        <div className="scrollContorl" />
                                                    </div>
                                                    <input
                                                        id="pac-input"
                                                        className="controls fl-wrap controls-mapwn"
                                                        autoComplete="on"
                                                        type="text"
                                                        placeholder="What Nearby? Schools, Gym... "
                                                        defaultValue
                                                    />
                                                </div> */}
                                                {/* list-single-main-item end */}
                                                <div className="google-map-code">
                                                    <div dangerouslySetInnerHTML={{ __html: property.mapUrl }} />
                                                </div>
                                                {/* list-single-main-item */}
                                                <div className="list-single-main-item fl-wrap">
                                                    <div className="list-single-main-item-title">
                                                        <h3>Local's View</h3>
                                                    </div>
                                                    <div className="list-single-main-item_content fl-wrap">
                                                        <div className="listing-features tag_view">
                                                            <ul>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fa-solid  fa-thumbs-up" /> 98% are
                                                                        side ways
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fa-solid  fa-thumbs-up" /> 95% Its
                                                                        dog friendly
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fa-solid  fa-thumbs-up" /> 95%
                                                                        Walkable Market
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fa-solid  fa-thumbs-up" /> 73%
                                                                        Nearby Roads
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fa-solid  fa-thumbs-up" /> 44%
                                                                        Nearby Metro/Stations
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <i className="fa-solid  fa-thumbs-up" /> 95%
                                                                        Walkable Hospital/Malls
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* list-single-main-item end */}


                                                <AreaInformation
                                                    lat={property?.latitude}
                                                    lng={property?.longitude}
                                                />


                                                <div
                                                    className="list-single-main-item fl-wrap"
                                                    id="sec6"
                                                >
                                                    <div className="list-single-main-item-title box-flex">
                                                        <h3>
                                                            Your Personal Note
                                                        </h3>
                                                        {
                                                            !showText &&
                                                            <i onClick={() => { setShowText(true) }} class="fa-solid fa-pen " style={{ cursor: 'pointer' }} />
                                                        }
                                                        {showText &&
                                                            <button
                                                                className='like-btn'
                                                                onClick={handleNoteSubmit}
                                                            >
                                                                save
                                                            </button>
                                                        }

                                                    </div>
                                                    <div className="list-single-main-item_content fl-wrap">
                                                        <div className="reviews-comments-item-text smpar">
                                                            <div className="box-widget-menu-btn smact">
                                                                <i className="far fa-ellipsis-h" />
                                                            </div>
                                                            <div className="clearfix" />
                                                            {privateNote?.note ? <>
                                                                <p>
                                                                    {privateNote?.note}
                                                                </p>
                                                                <div className="reviews-comments-item-date">
                                                                    <span className="reviews-comments-item-date-item">
                                                                        <i class="fa-solid fa-calendar-check"></i>
                                                                        {formatDate(privateNote?.updatedAt)}
                                                                    </span>
                                                                </div>
                                                            </> :
                                                                <p>
                                                                    Write a personal note (only you will be abe to see)
                                                                </p>
                                                            }
                                                            <div className="clearfix" />
                                                            {showText &&
                                                                <p style={{ marginTop: '2rem' }}>
                                                                    <textarea
                                                                        placeholder='...'
                                                                        className="form-control"
                                                                        name="note"
                                                                        value={formNoteData.note}
                                                                        onChange={handleNoteChange}
                                                                    />
                                                                </p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div
                                                    className="list-single-main-item fl-wrap"
                                                    id="sec6"
                                                >
                                                    <div className="list-single-main-item-title">
                                                        <h3>
                                                            Ratings <span>{property?.propertyReview?.length}</span>
                                                        </h3>
                                                    </div>
                                                    <div className="list-single-main-item_content fl-wrap">
                                                        <div className="reviews-comments-wrap fl-wrap">
                                                            {property?.propertyReview?.map((data) => (
                                                                <div className="reviews-comments-item">
                                                                    <div className="review-comments-avatar">
                                                                        <img
                                                                            src={data?.customer?.agencyDetails[0]?.photo ? `${apiUrl}/uploads/${data?.customer?.agencyDetails[0]?.photo}` : (data?.customer?.userDetails[0]?.photo ? `${apiUrl}/uploads/${data?.customer?.userDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/avatar/2.jpg")}
                                                                            alt="reviewer"
                                                                        />
                                                                    </div>
                                                                    <div className="reviews-comments-item-text smpar">
                                                                        <div className="box-widget-menu-btn smact">
                                                                            <i className="fa-solid fa-ellipsis-h" />
                                                                        </div>
                                                                        <div className="show-more-snopt-tooltip bxwt">
                                                                            <a href="#">
                                                                                {" "}
                                                                                <i className="fas fa-reply" /> Reply
                                                                            </a>
                                                                            <a href="#">
                                                                                {" "}
                                                                                <i className="fas fa-exclamation-triangle" />{" "}
                                                                                Report{" "}
                                                                            </a>
                                                                        </div>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                            <h4 style={{ marginBottom: "0px" }}>
                                                                                <a href="#">{data?.customer?.name}</a>
                                                                            </h4>
                                                                            {
                                                                                <div style={{ paddingLeft: '0px' }}>
                                                                                    {[1, 2, 3, 4, 5].map((star) => {
                                                                                        return (
                                                                                            <span
                                                                                                className='start'
                                                                                                style={{
                                                                                                    color: Number(data?.rating) >= star ? 'gold' : 'gray',
                                                                                                    fontSize: `30px`
                                                                                                }}
                                                                                            >
                                                                                                {' '}
                                                                                                ★{' '}
                                                                                            </span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="clearfix" />

                                                                        <div className="reviews-comments-item-date">
                                                                            <span className="reviews-comments-item-date-item">
                                                                                <i className="fa-solid fa-calendar-check" />
                                                                                {new Date(data?.createdAt).toDateString()}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* list-single-main-item end */}

                                                {/* {(user.id && isReviewed) &&
                                                    <div
                                                        className="list-single-main-item fl-wrap"
                                                        id="sec15"
                                                    >
                                                        <div className="list-single-main-item-title fl-wrap" style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'start',
                                                            gap: '6rem'
                                                        }}>
                                                            <h3>Add Your Review</h3>
                                                            <fieldset>
                                                                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                                    {[1, 2, 3, 4, 5].map((star) => {
                                                                        return (
                                                                            <span
                                                                                className='start'
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    color: rating >= star ? 'gold' : 'gray',
                                                                                    fontSize: `35px`,
                                                                                }}
                                                                                onClick={() => {
                                                                                    setRating(star)
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                ★{' '}
                                                                            </span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div id="add-review" className="add-review-box">
                                                            <form
                                                                className="add-comment custom-form"
                                                                onSubmit={handleReviewSubmit}
                                                            >
                                                                <button
                                                                    className="btn big-btn color-bg float-btn text-white"
                                                                    type='submit'
                                                                    disabled={(property.customerId === user?.id) || !user?.id}
                                                                    style={{ float: 'right' }}
                                                                >
                                                                    Submit Review{" "}
                                                                    <i
                                                                        className="fa fa-paper-plane-o"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                } */}
                                                {/* list-single-main-item */}
                                                {/* <div
                                                    className="list-single-main-item fl-wrap"
                                                    id="sec6"
                                                >
                                                    <div className="list-single-main-item-title">
                                                        <h3>
                                                            Reviews <span>{property?.propertyReview?.length}</span>
                                                        </h3>
                                                    </div>
                                                    <div className="list-single-main-item_content fl-wrap">
                                                        <div className="reviews-comments-wrap fl-wrap">
                                                            <div className="review-total">
                                                                <span className="review-number blue-bg">
                                                                    4.0
                                                                </span>
                                                                <div
                                                                    className="listing-rating card-popup-rainingvis"
                                                                    data-starrating2={4}
                                                                >
                                                                    <span className="re_stars-title">Good</span>
                                                                </div>
                                                            </div>
                                                            {property?.propertyReview?.map((data) => (
                                                                <div className="reviews-comments-item">
                                                                    <div className="review-comments-avatar">
                                                                        <img
                                                                            src={data?.customer?.agencyDetails[0]?.photo ? `${apiUrl}/uploads/${data?.customer?.agencyDetails[0]?.photo}` : (data?.customer?.userDetails[0]?.photo ? `${apiUrl}/uploads/${data?.customer?.userDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/avatar/2.jpg")}
                                                                            alt="reviewer"
                                                                        />
                                                                    </div>
                                                                    <div className="reviews-comments-item-text smpar">
                                                                        <div className="box-widget-menu-btn smact">
                                                                            <i className="fa-solid fa-ellipsis-h" />
                                                                        </div>
                                                                        <div className="show-more-snopt-tooltip bxwt">
                                                                            <a href="#">
                                                                                {" "}
                                                                                <i className="fas fa-reply" /> Reply
                                                                            </a>
                                                                            <a href="#">
                                                                                {" "}
                                                                                <i className="fas fa-exclamation-triangle" />{" "}
                                                                                Report{" "}
                                                                            </a>
                                                                        </div>
                                                                        <div style={{ display: 'grid', justifyContent: 'start', alignItems: 'center' }}>
                                                                            <h4 style={{ marginBottom: "0px" }}>
                                                                                <a href="#">{data?.customer?.name}</a>
                                                                            </h4>
                                                                            {
                                                                                <div style={{ paddingLeft: '0px' }}>
                                                                                    {[1, 2, 3, 4, 5].map((star) => {
                                                                                        return (
                                                                                            <span
                                                                                                className='start'
                                                                                                style={{                                                                                                  
                                                                                                    color: Number(data?.rating) >= star ? 'gold' : 'gray',
                                                                                                    fontSize: `30px`
                                                                                                }}
                                                                                            >
                                                                                                {' '}
                                                                                                ★{' '}
                                                                                            </span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                        <div
                                                                            className="listing-rating card-popup-rainingvis"
                                                                            data-starrating2={3}
                                                                        >
                                                                            <span className="re_stars-title">
                                                                                Average
                                                                            </span>
                                                                        </div>
                                                                        <div className="clearfix" />
                                                                        <p>
                                                                            " {data?.content} "
                                                                        </p>
                                                                        <div className="reviews-comments-item-date">
                                                                            <span className="reviews-comments-item-date-item">
                                                                                <i className="fa-solid fa-calendar-check" />
                                                                                {new Date(data?.createdAt).toDateString()}
                                                                            </span>
                                                                            <a href="#" className="rate-review">
                                                                                <i className="fa-solid  fa-thumbs-up" /> Helpful
                                                                                Review <span>6</span>{" "}
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            reviews-comments-item
                                                            <div className="reviews-comments-item">
                                                                <div className="review-comments-avatar">
                                                                    <img
                                                                        src="https://hauzzi.webkype.net/images/avatar/2.jpg"
                                                                        alt
                                                                    />
                                                                </div>
                                                                <div className="reviews-comments-item-text smpar">
                                                                    <div className="box-widget-menu-btn smact">
                                                                        <i className="far fa-ellipsis-h" />
                                                                    </div>
                                                                    <div className="show-more-snopt-tooltip bxwt">
                                                                        <a href="#">
                                                                            {" "}
                                                                            <i className="fas fa-reply" /> Reply
                                                                        </a>
                                                                        <a href="#">
                                                                            {" "}
                                                                            <i className="fas fa-exclamation-triangle" />{" "}
                                                                            Report{" "}
                                                                        </a>
                                                                    </div>
                                                                    <h4>
                                                                        <a href="#">Liza Rose</a>
                                                                    </h4>
                                                                    <div
                                                                        className="listing-rating card-popup-rainingvis"
                                                                        data-starrating2={3}
                                                                    >
                                                                        <span className="re_stars-title">
                                                                            Average
                                                                        </span>
                                                                    </div>
                                                                    <div className="clearfix" />
                                                                    <p>
                                                                        " Donec quam felis, ultricies nec,
                                                                        pellentesque eu, pretium quis, sem. Nulla
                                                                        consequat massa quis enim. Donec pede justo,
                                                                        fringilla vel, aliquet nec, vulputate eget,
                                                                        arcu. In enim justo, rhoncus ut, imperdiet
                                                                        a, venenatis vitae, justo. Nullam dictum
                                                                        felis eu pede mollis pretium. "
                                                                    </p>
                                                                    <div className="reviews-comments-item-date">
                                                                        <span className="reviews-comments-item-date-item">
                                                                            <i className="far fa-calendar-check" />
                                                                            12 April 2018
                                                                        </span>
                                                                        <a href="#" className="rate-review">
                                                                            <i className="fa-solid  fa-thumbs-up" /> Helpful
                                                                            Review <span>6</span>{" "}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            reviews-comments-item end
                                                            reviews-comments-item
                                                            <div className="reviews-comments-item">
                                                                <div className="review-comments-avatar">
                                                                    <img
                                                                        src="https://hauzzi.webkype.net/images/avatar/3.jpg"
                                                                        alt
                                                                    />
                                                                </div>
                                                                <div className="reviews-comments-item-text smpar">
                                                                    <div className="box-widget-menu-btn smact">
                                                                        <i className="far fa-ellipsis-h" />
                                                                    </div>
                                                                    <div className="show-more-snopt-tooltip bxwt">
                                                                        <a href="#">
                                                                            {" "}
                                                                            <i className="fas fa-reply" /> Reply
                                                                        </a>
                                                                        <a href="#">
                                                                            {" "}
                                                                            <i className="fas fa-exclamation-triangle" />{" "}
                                                                            Report{" "}
                                                                        </a>
                                                                    </div>
                                                                    <h4>
                                                                        <a href="#">Adam Koncy</a>
                                                                    </h4>
                                                                    <div
                                                                        className="listing-rating card-popup-rainingvis"
                                                                        data-starrating2={5}
                                                                    >
                                                                        <span className="re_stars-title">
                                                                            Excellent
                                                                        </span>
                                                                    </div>
                                                                    <div className="clearfix" />
                                                                    <p>
                                                                        " Lorem ipsum dolor sit amet, consectetur
                                                                        adipiscing elit. Nunc posuere convallis
                                                                        purus non cursus. Cras metus neque, gravida
                                                                        sodales massa ut. "
                                                                    </p>
                                                                    <div className="reviews-comments-item-date">
                                                                        <span className="reviews-comments-item-date-item">
                                                                            <i className="far fa-calendar-check" />
                                                                            03 December 2017
                                                                        </span>
                                                                        <a href="#" className="rate-review">
                                                                            <i className="fa-solid  fa-thumbs-up" /> Helpful
                                                                            Review <span>2</span>{" "}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            reviews-comments-item end
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* list-single-main-item end */}

                                                {/* {user.id &&

                                                    <div
                                                        className="list-single-main-item fl-wrap"
                                                        id="sec15"
                                                    >
                                                        <div className="list-single-main-item-title fl-wrap">
                                                            <h3>Add Your Review</h3>
                                                        </div>
                                                        Add Review Box
                                                        <div id="add-review" className="add-review-box">



                                                           
                                                            Review Comment
                                                            <form
                                                                className="add-comment custom-form"
                                                                onSubmit={handleReviewSubmit}
                                                            >
                                                                <fieldset>
                                                                 
                                                                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', marginBottom: '10px' }}>
                                                                        <h2 style={{ width: '15%', alignItems: 'center', fontSize: 'small', fontWeight: '600', color: '#566985' }}>
                                                                            RATING -
                                                                        </h2>
                                                                        {[1, 2, 3, 4, 5].map((star) => {
                                                                            return (
                                                                                <span
                                                                                    className='start'
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        color: rating >= star ? 'gold' : 'gray',
                                                                                        fontSize: `35px`,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setRating(star)
                                                                                    }}
                                                                                >
                                                                                    {' '}
                                                                                    ★{' '}
                                                                                </span>
                                                                            )
                                                                        })}
                                                                    </div>

                                                                    <textarea
                                                                        cols={40}
                                                                        rows={3}
                                                                        placeholder="Your Review:"
                                                                        name='content'
                                                                        defaultValue={""}
                                                                        required={true}
                                                                        onChange={(e) => {
                                                                            handleReviewChange(e)
                                                                        }}

                                                                    />
                                                                </fieldset>
                                                                <button
                                                                    className="btn big-btn color-bg float-btn text-white"
                                                                    type='submit'
                                                                    disabled={(property.customerId === user?.id) || !user?.id}
                                                                >
                                                                    Submit Review{" "}
                                                                    <i
                                                                        className="fa fa-paper-plane-o"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </form>
                                                        </div>
                                                        Add Review Box / End
                                                    </div>
                                                } */}
                                                {/* list-single-main-item end */}
                                                <div className="list-single-main-container">
                                                    {/*   list-single-main-item */}
                                                    <div
                                                        className="list-single-main-item fl-wrap"
                                                        id="faq1"
                                                    >
                                                        <div className="list-single-main-item-title big-lsmt fl-wrap">
                                                            <h3>FAQ</h3>
                                                        </div>
                                                        {/*   accordion-lite */}
                                                        {listingData.map((data, index) => (
                                                            <div className="accordion" id={`panelsStayOpen-${index}`}>
                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" id={`panelsStayOpen-o${index}`}>
                                                                        <button
                                                                            className="accordion-button collapsed"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#panelsStayOpen-c${index}`}
                                                                            aria-expanded="false"
                                                                            aria-controls={`panelsStayOpen-c${index}`}
                                                                        >
                                                                            {data.query}
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={`panelsStayOpen-c${index}`}
                                                                        className="accordion-collapse collapse"
                                                                        aria-labelledby={`panelsStayOpen-o${index}`}
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <strong>{data.answer}</strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {/*   accordion-lite end */}
                                                        {/*   accordion-lite */}
                                                        {/* <div className="accordion-lite-container fl-wrap">
                                                            <div className="accordion-lite-header fl-wrap">
                                                                Can I use more than one payment method to pay
                                                                for a reservation? <i className="fas fa-plus" />
                                                            </div>
                                                            <div className="accordion-lite_content fl-wrap">
                                                                <p>
                                                                    {" "}
                                                                    Nulla finibus lobortis pulvinar. Donec a
                                                                    consectetur nulla. Nulla posuere sapien vitae
                                                                    lectus suscipit, et pulvinar nisi tincidunt.
                                                                    Aliquam erat volutpat. Curabitur convallis
                                                                    fringilla diam sed aliquam. Sed tempor iaculis
                                                                    massa faucibus feugiat. In fermentum facilisis
                                                                    massa, a consequat .
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                        {/*   accordion-lite end */}
                                                        {/*   accordion-lite */}
                                                        {/* <div className="accordion-lite-container fl-wrap">
                                                            <div className="accordion-lite-header fl-wrap">
                                                                How do I edit or remove a payment method?{" "}
                                                                <i className="fas fa-plus" />
                                                            </div>
                                                            <div className="accordion-lite_content fl-wrap">
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur
                                                                    adipiscing elit. Maecenas in pulvinar neque.
                                                                    Nulla finibus lobortis pulvinar. Donec a
                                                                    consectetur nulla. Nulla posuere sapien vitae
                                                                    lectus suscipit, et pulvinar nisi tincidunt.
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                        {/*   accordion-lite end */}
                                                        {/*   accordion-lite */}
                                                        {/* <div className="accordion-lite-container fl-wrap">
                                                            <div className="accordion-lite-header fl-wrap">
                                                                Can I pay my own taxes and insurance?{" "}
                                                                <i className="fas fa-plus" />
                                                            </div>
                                                            <div className="accordion-lite_content fl-wrap">
                                                                <p>
                                                                    {" "}
                                                                    Nulla finibus lobortis pulvinar. Donec a
                                                                    consectetur nulla. Nulla posuere sapien vitae
                                                                    lectus suscipit, et pulvinar nisi tincidunt.
                                                                    Aliquam erat volutpat. Curabitur convallis
                                                                    fringilla diam sed aliquam. Sed tempor iaculis
                                                                    massa faucibus feugiat. In fermentum facilisis
                                                                    massa, a consequat .
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                        {/*   accordion-lite end */}
                                                        {/*   accordion-lite */}
                                                        {/* <div className="accordion-lite-container fl-wrap">
                                                            <div className="accordion-lite-header fl-wrap">
                                                                How long does the loan process take?
                                                                <i className="fas fa-plus" />
                                                            </div>
                                                            <div className="accordion-lite_content fl-wrap">
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur
                                                                    adipiscing elit. Maecenas in pulvinar neque.
                                                                    Nulla finibus lobortis pulvinar. Donec a
                                                                    consectetur nulla. Nulla posuere sapien vitae
                                                                    lectus suscipit, et pulvinar nisi tincidunt.
                                                                </p>
                                                            </div>
                                                        </div> */}
                                                        {/*   accordion-lite end */}
                                                    </div>
                                                    {/*   list-single-main-item end */}
                                                </div>
                                                {/* list-single-main-item */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* listing-single content end*/}
                                    {/* sidebar */}
                                    <div className="col-md-4">
                                        {/*box-widget*/}
                                        <div className="box-widget fl-wrap">
                                            <div className="profile-widget">
                                                <div className="profile-widget-header color-bg smpar fl-wrap">
                                                    <div className="pwh_bg" />
                                                    <div className="call-btn">
                                                        <a
                                                            // href="#1"
                                                            className="tolt"
                                                            data-microtip-position="right"
                                                            data-tooltip="Whatsapp Now"
                                                            onClick={() => {
                                                                console.log(property?.userDetails?.[0]?.phone)
                                                                openWhatsApp(property?.Customer?.agentDetails?.[0]?.phone)
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <i class="fa-brands fa-whatsapp fa-xl" style={{ color: "#0f0f0f" }}></i>
                                                        </a>
                                                    </div>

                                                    <div className="box-widget-menu-btn smact">
                                                        <i className="fa-solid fa-ellipsis-h" />
                                                    </div>
                                                    <div className="show-more-snopt-tooltip bxwt">
                                                        {Token &&
                                                            <a href={Token ? `profile-info-${postBy?.id}` : `#`}>
                                                                {" "}
                                                                <i className="fa-solid fa-comment-alt" /> Write a
                                                                review
                                                            </a>
                                                        }

                                                        <a href="#">
                                                            {" "}
                                                            <i className="fa-solid fa-exclamation-triangle" />{" "}
                                                            Report{" "}
                                                        </a>
                                                    </div>
                                                    <div className="profile-widget-card">
                                                        <div className="profile-widget-image" a>
                                                            {property?.Customer?.userType === 0 && <img
                                                                src={property.Customer.userDetails[0]?.photo ? `${apiUrl}/uploads/${property.Customer.userDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/avatar/1.jpg"}
                                                                alt="Posted By"
                                                            />}
                                                            {property?.Customer?.userType === 1 && <img
                                                                src={property.Customer.agentDetails[0]?.photo ? `${apiUrl}/uploads/${property.Customer.agentDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/avatar/1.jpg"}
                                                                alt="Posted By"
                                                            />}
                                                            {property?.Customer?.userType === 2 && <img
                                                                src={property.Customer.agencyDetails[0]?.photo ? `${apiUrl}/uploads/${property.Customer.agencyDetails[0]?.photo}` : "https://hauzzi.webkype.net/images/avatar/1.jpg"}
                                                                alt="Posted By"
                                                            />}
                                                        </div>
                                                        <div className="profile-widget-header-title">
                                                            {Token ?
                                                                <h4>
                                                                    <a href={`/profile-info-${postBy?.id}`}>{property?.postedBy}</a>
                                                                </h4> :
                                                                <h4>
                                                                    {property?.postedBy}
                                                                </h4>
                                                            }

                                                            <div className="clearfix" />
                                                            <div className="pwh_counter">
                                                                <span>{userList?.length}</span>Property Listings
                                                            </div>
                                                            <div className="clearfix" />
                                                            {/* <div
                                                                className="listing-rating card-popup-rainingvis"
                                                                data-starrating2={4}
                                                            /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="profile-widget-content fl-wrap">
                                                    <div className="contats-list fl-wrap">
                                                        <ul className="no-list-style">
                                                            <li>
                                                                <span>
                                                                    <i className="fa-solid  fa-phone" /> Phone :
                                                                </span>{" "}
                                                                <a href="#">{postBy?.userDetails?.phone}</a>
                                                                {/* <a href="#">xxxxxxxxxx</a> */}
                                                            </li>

                                                            <li>
                                                                <span>
                                                                    <i className="fa-solid  fa-envelope" /> Mail :
                                                                </span>{" "}
                                                                <a href="#">
                                                                    <span
                                                                        className="__cf_email__"
                                                                        data-cfemail="7a301f0909131f2d13161915023a1e15171b131454191517"
                                                                    >
                                                                        {postBy?.email}
                                                                    </span>
                                                                </a>
                                                                {/* <a href="#">
                                                                    <span
                                                                        className="__cf_email__"
                                                                        data-cfemail="7a301f0909131f2d13161915023a1e15171b131454191517"
                                                                    >
                                                                        xxxxxxxx@xxxx.com
                                                                    </span>
                                                                </a> */}

                                                            </li>
                                                            <li>
                                                                <span>
                                                                    <i className="fa-solid  fa-globe" /> Website :
                                                                </span>{" "}
                                                                <a href="#">{postBy?.userDetails?.website}</a>
                                                                {/* <a href="#">www.xxxxxxxxxx.com</a> */}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {Token &&
                                                        <div className="profile-widget-footer fl-wrap">
                                                            <a
                                                                href={`/profile-info-${postBy?.id}`}

                                                                className="btn float-btn color-bg small-btn text-white"
                                                            >
                                                                View Profile
                                                            </a>
                                                            <a
                                                                href="#sec-contact"
                                                                className="custom-scroll-link tolt"
                                                                data-microtip-position="left"
                                                                data-tooltip="Viewing Property"
                                                            >
                                                                <i className="fa-solid  fa-paper-plane" />
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/*box-widget end */}
                                        {/*box-widget*/}
                                        <div className="box-widget fl-wrap">
                                            <div className="box-widget-title fl-wrap"  >
                                                Featured Properties
                                            </div>
                                            <div className="box-widget-content fl-wrap">
                                                {/*widget-posts*/}
                                                <div className="widget-posts  fl-wrap">
                                                    <ul className="no-list-style">
                                                        {propertyList?.map((prop) => (
                                                            <li>
                                                                <div className="widget-posts-img">
                                                                    <a href={prop.slug}>
                                                                        <img src={`${apiUrl}/uploads/${prop.photo1 ? prop.photo1 : prop.photo2}`} alt="property" style={{ height: '85px' }} />
                                                                    </a>
                                                                </div>
                                                                <div className="widget-posts-descr">
                                                                    <h4>
                                                                        <a href={prop.slug}>
                                                                            {prop.name}
                                                                        </a>
                                                                    </h4>
                                                                    <div className="geodir-category-location fl-wrap">
                                                                        <a href="#">
                                                                            <i className="fa-solid fa-location-dot" /> {prop?.cities?.name}
                                                                        </a>
                                                                    </div>

                                                                    {prop.purpose === 'wantToSell' ? <div className="geodir-category-content_price">
                                                                        $ {prop.maxPrice}
                                                                    </div> : <div className="geodir-category-content_price">
                                                                        $ {prop.rentalPrice}/Month
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </li>
                                                        ))}
                                                        {/* <li>
                                                            <div className="widget-posts-img">
                                                                <a href="listing-single.html">
                                                                    <img
                                                                        src="https://hauzzi.webkype.net/images/all/small/2.jpg"
                                                                        alt
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="widget-posts-descr">
                                                                <h4>
                                                                    <a href="listing-single.html">Family House</a>
                                                                </h4>
                                                                <div className="geodir-category-location fl-wrap">
                                                                    <a href="#">
                                                                        <i className="fas fa-map-marker-alt" /> 70
                                                                        Bright St New York, USA{" "}
                                                                    </a>
                                                                </div>
                                                                <div className="widget-posts-descr-price">
                                                                    $ 50000
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="widget-posts-img">
                                                                <a href="listing-single.html">
                                                                    <img
                                                                        src="https://hauzzi.webkype.net/images/all/small/3.jpg"
                                                                        alt
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="widget-posts-descr">
                                                                <h4>
                                                                    <a href="listing-single.html">
                                                                        Apartment to Rent
                                                                    </a>
                                                                </h4>
                                                                <div className="geodir-category-location fl-wrap">
                                                                    <a href="#">
                                                                        <i className="fas fa-map-marker-alt" />
                                                                        75 Prince St, NY, USA
                                                                    </a>
                                                                </div>
                                                                <div className="widget-posts-descr-price">
                                                                    <span>Price: </span> $100 / per night
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="widget-posts-img">
                                                                <a href="listing-single.html">
                                                                    <img
                                                                        src="https://hauzzi.webkype.net/images/all/small/3.jpg"
                                                                        alt
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="widget-posts-descr">
                                                                <h4>
                                                                    <a href="listing-single.html">
                                                                        Apartment to Rent
                                                                    </a>
                                                                </h4>
                                                                <div className="geodir-category-location fl-wrap">
                                                                    <a href="#">
                                                                        <i className="fas fa-map-marker-alt" />
                                                                        75 Prince St, NY, USA
                                                                    </a>
                                                                </div>
                                                                <div className="widget-posts-descr-price">
                                                                    <span>Price: </span> $100 / per night
                                                                </div>
                                                            </div>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                                {/* widget-posts end*/}
                                                <a
                                                    href="listing"
                                                    className="btn float-btn color-bg small-btn text-white"
                                                >
                                                    View All Properties
                                                </a>
                                            </div>
                                        </div>
                                        {/*box-widget end */}
                                        {/*box-widget*/}
                                        {/* <div
                                            className="box-widget fl-wrap hidden-section"
                                            style={{ marginTop: 30 }}
                                        >
                                            <div className="box-widget-content fl-wrap color-bg">
                                                <div className="color-form reset-action">
                                                    <div className="color-form-title fl-wrap">
                                                        <h4>Calculate Your Mortgage</h4>
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit. Nunc posuere convallis purus non cursus.{" "}
                                                        </p>
                                                    </div>
                                                    <form method="post" name="mortgage-form">
                                                        <div className="fl-wrap">
                                                            <label htmlFor="amt">Loan Amount </label>
                                                            <input
                                                                id="amt"
                                                                name="amt"
                                                                type="text"
                                                                placeholder={0}
                                                                defaultValue={0}
                                                            />
                                                            <div
                                                                className="use-current-price tolt"
                                                                data-microtip-position="left"
                                                                data-tooltip="Use current price"
                                                            >
                                                                <i className="fa-solid  fa-tag" />
                                                            </div>
                                                        </div>
                                                        <label htmlFor="apr">Percentage rate</label>
                                                        <div className="price-rage-item fl-wrap">
                                                            <input
                                                                type="text"
                                                                id="apr"
                                                                name="apr"
                                                                className="price-range"
                                                                data-min={0}
                                                                data-max={100}
                                                                data-step={1}
                                                                defaultValue={0}
                                                                data-prefix="%"
                                                            />
                                                        </div>
                                                        <label htmlFor="trm">Loan Term (Years) </label>
                                                        <div className="price-rage-item fl-wrap">
                                                            <input
                                                                type="text"
                                                                id="trm"
                                                                name="trm"
                                                                className="price-range"
                                                                data-min={0}
                                                                data-max={5}
                                                                data-step={1}
                                                                defaultValue={0}
                                                                data-prefix="Y"
                                                            />
                                                        </div>
                                                        <div className="clearfix" />
                                                        <button
                                                            type="button"
                                                            id="sbt"
                                                            className="color2-bg"
                                                        >
                                                            Calculate
                                                        </button>
                                                        <div className="reset-form reset-btn">
                                                            {" "}
                                                            <i className="far fa-sync-alt" /> Reset Form
                                                        </div>
                                                        <div className="monterage-title fl-wrap">
                                                            <h5>Monthly payment:</h5>
                                                            <input
                                                                type="text"
                                                                id="pmt"
                                                                name="mPmt"
                                                                defaultValue={0}
                                                            />
                                                            <div className="monterage-title-item">
                                                                $<span />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/*box-widget end */}
                                        {/*box-widget*/}
                                        {/* <div className="box-widget fl-wrap">
                                            <div className="box-widget-title fl-wrap">
                                                Propertie Documents
                                            </div>
                                            <div className="box-widget-content fl-wrap">
                                                <div className="bwc_download-list">
                                                    <a href={`${apiUrl}/uploads/${property.propertyDoc}`} download>
                                                        <span>
                                                            <i className="fa-solid  fa-file-pdf" />
                                                        </span>
                                                        Property Presentation
                                                    </a>
                                                    <a href={`${apiUrl}/uploads/${property.propertyDoc1}`} download>
                                                        <span>
                                                            <i className="fa-solid  fa-file-word" />
                                                        </span>
                                                        Energetic Certificate
                                                    </a>
                                                    <a href={`${apiUrl}/uploads/${property.propertyDoc2}`} download>
                                                        <span>
                                                            <i className="fa-solid  fa-file-pdf" />
                                                        </span>
                                                        Property Plans
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/*box-widget end */}
                                        {/*box-widget*/}
                                        {(property.customerId !== user?.id) && user?.id ? <div className="box-widget fl-wrap">
                                            <div
                                                className="box-widget-fixed-init fl-wrap"
                                                id="sec-contact"
                                            >
                                                <div className="box-widget-title fl-wrap box-widget-title-color color-bg">
                                                    Contact Property
                                                </div>
                                                <div className="box-widget-content fl-wrap">
                                                    <div className="custom-form">
                                                        <form
                                                            method="post"
                                                            name="contact-property-form"
                                                            onSubmit={handleSubmit}
                                                        >
                                                            {/* <label>
                                                                Your name*{" "}
                                                                <span className="dec-icon">
                                                                    <FontAwesomeIcon icon="fa-solid  fa-user" />
                                                                </span>
                                                            </label>
                                                            <input
                                                                name="name"
                                                                type="text"
                                                                value={formData.name}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                }}
                                                                required={true}
                                                            />
                                                            <label>
                                                                Your phone *{" "}
                                                                <span className="dec-icon">
                                                                    <FontAwesomeIcon icon="fa-solid  fa-phone" />
                                                                </span>
                                                            </label>
                                                            <input
                                                                name="phone"
                                                                type="text"
                                                                value={formData.phone}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                }}
                                                                required={true}
                                                            />
                                                            <label>
                                                                Your Email *{" "}
                                                                <span className="dec-icon">
                                                                    <FontAwesomeIcon icon="fa-solid  fa-envelope" />
                                                                </span>
                                                            </label>
                                                            <input
                                                                name="email"
                                                                type="text"
                                                                value={formData.email}
                                                                onChange={(e) => {
                                                                    handleChange(e)
                                                                }}
                                                                required={true}
                                                            /> */}
                                                            {/* <div className="row">
                                                                <div className="col-sm-6">
                                                                    <label>
                                                                        Date{" "}
                                                                        <span className="dec-icon">
                                                                            <i className="fas fa-calendar-check" />
                                                                        </span>
                                                                    </label>
                                                                    <div className="date-container fl-wrap">
                                                                        <input
                                                                            type="text"
                                                                            placeholder
                                                                            style={{ padding: "16px 5px 16px 60px" }}
                                                                            name="datepicker-here"
                                                                            defaultValue
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label>Time</label>
                                                                    <select
                                                                        data-placeholder="9 AM"
                                                                        className="chosen-select on-radius no-search-select"
                                                                    >
                                                                        <option>9 AM</option>
                                                                        <option>10 AM</option>
                                                                        <option>11 AM</option>
                                                                        <option>12 AM</option>
                                                                        <option>13 PM</option>
                                                                        <option>14 PM</option>
                                                                        <option>15 PM</option>
                                                                        <option>16 PM</option>
                                                                    </select>
                                                                </div>
                                                            </div> */}
                                                            <button
                                                                type="submit"
                                                                className="btn float-btn color-bg fw-btn"
                                                            // onClick={(e) => {
                                                            //     handleSubmit(e)
                                                            // }}
                                                            >
                                                                {" "}
                                                                Send Enquiry
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <></>}
                                        {/*box-widget end */}
                                    </div>
                                    {/*  sidebar end*/}
                                </div>
                                <div className="fl-wrap limit-box" />


                                <div className='carousel slide mt-4' id="propertyCarousel" data-bs-ride="carousel">
                                    <div className="list-single-main-item-title">
                                        <h3>Similar Properties</h3>
                                    </div>
                                    <div className='carousel-inner'>
                                        {propertyList.map((prop, i) => (
                                            <div
                                                key={prop.slug}
                                                className={`gallery-item for_${prop.purpose !== 'wantToRent' ? 'sale' : 'rent'}`}
                                                onClick={() => {
                                                    window.location.href = prop.slug
                                                }}
                                                role="button"
                                                style={{ marginBottom: '10px' }}
                                            >
                                                <article className="geodir-category-listing fl-wrap" >
                                                    <div className="geodir-category-img fl-wrap" >
                                                        <p
                                                            className="geodir-category-img_item"
                                                        >
                                                            <img src={`${apiUrl}/uploads/${prop.photo1 ? prop.photo1 : prop.photo2}`} alt="property" style={{ height: '250px' }} />
                                                            <span className="overlay" />
                                                        </p>
                                                        <div className="geodir-category-location">
                                                            <a
                                                                href="#1"
                                                                className="map-item tolt"
                                                                data-microtip-position="top-left"
                                                                data-tooltip="On the map"
                                                            >
                                                                <FontAwesomeIcon icon="fa-solid  fa-map-marker-alt" />

                                                                {prop.cities.name} ,{prop.states.name} , {prop.countries.name}
                                                            </a>
                                                        </div>
                                                        <ul className="list-single-opt_header_cat">
                                                            <li>
                                                                <a href="#2" className="cat-opt blue-bg">
                                                                    {prop.purpose !== 'wantToRent' ? 'Sale' : 'Rent'}
                                                                </a>
                                                            </li>
                                                            {prop.purpose === 'bothSellRent' && <li>
                                                                <a href="#3" className="cat-opt color-bg">
                                                                    Rent
                                                                </a>
                                                            </li>}
                                                            <li>
                                                                <a href="#" className="cat-opt color-bg">
                                                                    {prop.category}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <p
                                                            className={`geodir_save-btn tolt text-center ${wishlist?.find((item) => item === prop.id) ? "text-danger" : ""}`}
                                                            data-microtip-position="left"
                                                            data-tooltip="Save"
                                                            onClick={(e) => {
                                                                handleWishlist(e, prop.id, user.id);
                                                            }}
                                                        >
                                                            <span>
                                                                <FontAwesomeIcon icon="fa-solid fa-heart" />
                                                            </span>
                                                        </p>
                                                        <p
                                                            className="compare-btn tolt text-center"
                                                            data-microtip-position="left"
                                                            data-tooltip="Compare"
                                                            onClick={(e) => {
                                                                handleCompare(e, prop.id, user.id)
                                                            }}
                                                        >
                                                            <span>
                                                                <FontAwesomeIcon icon="fa-solid  fa-random" />
                                                            </span>
                                                        </p>

                                                    </div>
                                                    <div
                                                        className="geodir-category-content fl-wrap"
                                                        role='button'
                                                    >
                                                        <h3>
                                                            <a>{
                                                                prop.name ? <>{prop.name.slice(0, 60)}</> :
                                                                    <>Gorgeous house for sale</>
                                                            }
                                                            </a>
                                                        </h3>
                                                        <div className="geodir-category-content_price">$ {prop.maxPrice}</div>

                                                        {
                                                            prop.description ?
                                                                <p>{prop.description.slice(0, 40)}...</p> :
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                    Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                                                                    Donec a consectetur nulla.
                                                                </p>
                                                        }
                                                        <div className="geodir-category-content-details">
                                                            <ul>
                                                                <li>
                                                                    <FontAwesomeIcon icon="fa-solid  fa-bed" />
                                                                    <span>{" "}{prop?.listingDetails?.Bedrooms}</span>
                                                                </li>
                                                                <li>
                                                                    <FontAwesomeIcon icon="fa-solid  fa-bath" />
                                                                    <span>{" "}{prop?.listingDetails?.Bathroom}</span>
                                                                </li>
                                                                <li>
                                                                    <FontAwesomeIcon icon="fa-solid  fa-cube" />
                                                                    <span>{" "}{prop.maxSize} Sq Ft</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="geodir-category-footer fl-wrap">

                                                            <a href={`${prop.slug}`} className="gcf-company">
                                                                <img src={prop?.Customer?.userDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : (prop?.Customer?.agentDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agentDetails[0]?.photo}` : (prop?.Customer?.agencyDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agencyDetails[0]?.photo}` : "images/avatar/2.jpg"))} alt="poster" style={{ objectFit: 'cover' }} />
                                                                {/* <img src={prop?.Customer?.userDetails ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : "images/avatar/2.jpg"} alt="poster" /> */}
                                                                <span>By {prop.postedBy}</span>
                                                            </a>
                                                            {/* <div
                                                            className="listing-rating card-popup-rainingvis"
                                                            data-microtip-position="top"
                                                            data-tooltip="Good"
                                                            data-starrating2={4}
                                                        /> */}
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        ))}

                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#propertyCarousel" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#propertyCarousel" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* content end */}
                    {/* subscribe-wrap */}
                    {/* <div className="subscribe-wrap fl-wrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src="https://miro.medium.com/v2/resize:fit:1400/1*VlZW9UyxDmzZECZbVprx3g.png" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* subscribe-wrap end */}
                    {/* footer */}
                    <Footer />
                    {/* <footer className="main-footer fl-wrap">
                        <div className="footer-inner fl-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="footer-widget fl-wrap">
                                            <div className="footer-widget-logo fl-wrap">
                                                <img
                                                    src="https://hauzzi.webkype.net/images/logo.png"
                                                    alt
                                                />
                                            </div>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                Maecenas in pulvinar neque. Nulla finibus lobortis
                                                pulvinar.
                                            </p>
                                            <div className="fw_hours fl-wrap">
                                                <span>
                                                    Monday - Friday:<strong> 8am - 6pm</strong>
                                                </span>
                                                <span>
                                                    Saturday - Sunday:<strong> 9am - 3pm</strong>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-widget fl-wrap">
                                            <div className="footer-widget-title fl-wrap">
                                                <h4>Helpful links</h4>
                                            </div>
                                            <ul className="footer-list fl-wrap">
                                                <li>
                                                    <a href="about.html">About Our Company</a>
                                                </li>
                                                <li>
                                                    <a href="blog.html">Our last News</a>
                                                </li>
                                                <li>
                                                    <a href="pricing.html">Pricing Plans</a>
                                                </li>
                                                <li>
                                                    <a href="contacts.html">Contacts</a>
                                                </li>
                                                <li>
                                                    <a href="help.html">Help Center</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-widget fl-wrap">
                                            <div className="footer-widget-title fl-wrap">
                                                <h4>Contacts Info</h4>
                                            </div>
                                            <ul className="footer-contacts fl-wrap">
                                                <li>
                                                    <span>
                                                        <i className="fa-solid  fa-envelope" /> Mail :
                                                    </span>
                                                    <a href="#" target="_blank">
                                                        <span
                                                            className="__cf_email__"
                                                            data-cfemail="661f0913140b070f0a2602090b070f084805090b"
                                                        >
                                                            [email&nbsp;protected]
                                                        </span>
                                                    </a>
                                                </li>
                                                <li>
                                                    {" "}
                                                    <span>
                                                        <i className="fa-solid  fa-map-marker" /> Adress :
                                                    </span>
                                                    <a href="#" target="_blank">
                                                        USA 27TH Brooklyn NY
                                                    </a>
                                                </li>
                                                <li>
                                                    <span>
                                                        <i className="fa-solid  fa-phone" /> Phone :
                                                    </span>
                                                    <a href="#">+7(111)123456789</a>
                                                </li>
                                            </ul>
                                            <div className="footer-social fl-wrap">
                                                <ul>
                                                    <li>
                                                        <a href="#" target="_blank">
                                                            <i className="fab fa-facebook-f" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" target="_blank">
                                                            <i className="fab fa-twitter" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" target="_blank">
                                                            <i className="fab fa-instagram" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" target="_blank">
                                                            <i className="fab fa-vk" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-widget fl-wrap">
                                            <div className="footer-widget-title fl-wrap">
                                                <h4>Download our API</h4>
                                            </div>
                                            <p>
                                                Start working with Webkype that can provide everything
                                                you need{" "}
                                            </p>
                                            <div className="api-links fl-wrap">
                                                <a href="#" className="api-btn color-bg">
                                                    <i className="fab fa-apple" /> App Store
                                                </a>
                                                <a href="#" className="api-btn color-bg">
                                                    <i className="fab fa-google-play" /> Play Market
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sub-footer gray-bg fl-wrap">
                            <div className="container">
                                <div className="copyright">
                                    {" "}
                                    © Webkype 2024 . All rights reserved.
                                </div>
                                <div className="subfooter-nav">
                                    <ul className="no-list-style">
                                        <li>
                                            <a href="#">Terms of use</a>
                                        </li>
                                        <li>
                                            <a href="#">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href="#">Blog</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer> */}
                    {/* footer end */}
                </div>
            </div>
            {/* Main end */}
            {/*=============== scripts  ===============*/}
        </div >
    )
}

export default SingleListing
