import React, { useState, useEffect } from "react";

const AreaInformation = ({lat, lng}) => {
    const [mapLoaded, setMapLoaded] = useState(false);
    const [places, setPlaces] = useState([]);
    const [category, setCategory] = useState("health");
    const [error, setError] = useState("");
    const [distances, setDistances] = useState([]);

    const categories = {
        education: ["school", "university"],
        "green Area": ["park"],
        shope: ["shopping_mall", "store", "supermarket"],
        health: ["hospital", "clinic"],
        transport: ["bus_station", "subway_station", "train_station"],
    };

    // const lat = 28.60745079537158;
    // const lng = 77.43540640884898;
    const API_KEY = process.env.REACT_APP_MAP_API_KEY;


    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places,directions`;
        script.async = true;
        script.onload = () => setMapLoaded(true);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const fetchNearbyPlaces = (lat, lng, category) => {
        if (!window.google || !window.google.maps) {
            setError("Google Maps API failed to load.");
            return;
        }

        const map = new window.google.maps.Map(document.createElement("div")); // Virtual map
        const service = new window.google.maps.places.PlacesService(map);

        const radius = 2000; // 2km radius
        const types = categories[category];
        let allResults = [];

        types.forEach((type) => {
            const request = {
                location: new window.google.maps.LatLng(lat, lng),
                radius,
                type,
            };

            service.nearbySearch(request, (results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    allResults = [...allResults, ...results];
                    setPlaces(allResults);
                } else {
                    console.error(`Error fetching places for type ${type}: ${status}`);
                    setError(`Failed to fetch places for ${type}`);
                }
            });
        });
    };

    const calculateWalkingDistances = (places) => {
        if (!window.google || !window.google.maps) return;

        const directionsService = new window.google.maps.DirectionsService();
        const origin = new window.google.maps.LatLng(lat, lng);

        const distancesPromises = places.map((place) => {
            const destination = place.geometry.location;

            return new Promise((resolve, reject) => {
                directionsService.route(
                    {
                        origin,
                        destination,
                        travelMode: window.google.maps.TravelMode.WALKING,
                    },
                    (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            const distance = result.routes[0].legs[0].distance.text;
                            const distanceInMeters = result.routes[0].legs[0].distance.value;

                            // Only keep places within 2km (2000 meters)
                            if (distanceInMeters <= 2000) {
                                resolve({ name: place.name, distance });
                            } else {
                                resolve({ name: place.name, distance: "Out of 2KM range" });
                            }
                        } else {
                            reject(`Failed to calculate distance to ${place.name}`);
                        }
                    }
                );
            });
        });

        Promise.all(distancesPromises)
            .then((distancesData) => {
                setDistances(distancesData);
            })
            .catch((err) => {
                console.error(err);
                setError("Failed to calculate walking distances.");
            });
    };

    useEffect(() => {
        if (mapLoaded) {
            fetchNearbyPlaces(lat, lng, category);
        }
    }, [mapLoaded, lat, lng, category]);

    useEffect(() => {
        if (places.length > 0) {
            calculateWalkingDistances(places);
        }
    }, [places]);

    return (
        <div className="list-single-main-item fl-wrap" id="sec6">
            <div className="list-single-main-item-title">
                <h2>Area Information</h2>
            </div>

            <div className="list-single-main-item_content fl-wrap">
                {/* Category Selection as Horizontal Buttons */}
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    marginBottom: "20px"
                }}>
                    {Object.keys(categories).map((cat) => (
                        <button
                            key={cat}
                            onClick={() => setCategory(cat)}
                            style={{
                                padding: "10px 20px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                backgroundColor: category === cat ? "#eda85f" : "#f1f1f1",
                                color: category === cat ? "#fff" : "#333",
                                cursor: "pointer",
                                fontSize: "14px",
                            }}
                        >
                            {cat.charAt(0).toUpperCase() + cat.slice(1)}
                        </button>
                    ))}
                </div>
                <div className="list-single-main-item_content fl-wrap">
                        <ul
                            style={{
                                display: "grid",
                                gridTemplateColumns: "auto auto",
                                textAlign: "left",
                                gap: "10px"
                            }}
                        >
                            {places.map((place, index) => (
                                <li key={index}>
                                    <strong>{place.name}</strong>
                                    {/* - {place.vicinity} -{" "} */}
                                    {/* {place.types?.join(", ")} */}
                                    {distances.length > 0 && (
                                        <p>
                                            Walking Distance:{" "}
                                            {distances.find((d) => d.name === place.name)?.distance || "N/A"}
                                        </p>
                                    )}
                                </li>
                            ))}
                        </ul>
                </div>



            </div>

            {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
        </div >
    );
};

export default AreaInformation;
