import React, { Component } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const apiUrl = process.env.REACT_APP_URL;


function Carousel({ photo }) {


    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "400px",
        slidesToShow: 1,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    return (
        <div className="slider-container">
            <Slider {...settings}>
                {photo.map((data, index) => (
                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                        <img src={`${apiUrl}/uploads/${data}`} alt="property" height={'500vh'} style={{ width: '100%', maxWidth:'750px', paddingInline: '10px' }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "black" }}
            onClick={onClick}
        />
    );
}

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "black" }}
            onClick={onClick}
        />
    );
}





export default Carousel;
