import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeroSection from "./HeroSection";
import Footer from "../Header/Footer";
import { toast } from 'react-toastify';
import DiscoverCities from "./DiscoverCities";
import Testimonial from "./Testimonial/Testimonial";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';


function Home() {
    const apiUrl = process.env.REACT_APP_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const Token = localStorage.getItem("Token");

    const [propertyList, setPropertyList] = useState([])
    const [purpose, setPurpose] = useState("")
    const [photoCount, setPhotoCount] = useState([])
    const [testimonial, setTestimonial] = useState([])
    const [wishlist, setWishlist] = useState([])
    const [user, setUser] = useState({})
    const [rightBanner, setRightBanners] = useState({})
    const [leftBanner, setLeftBanners] = useState({})
    const [endLeftBanner, setEndLeftBanner] = useState({})
    const [endRightBanner, setEndRightBanner] = useState({})

    useEffect(() => {
        fetch(`${apiUrl}/banner/getAllBanner`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {


                    for (let i = 0; i < data.data.length; i++) {

                        if (data.data[i].isVisible && data.data[i].position === 'SecondRightBanner') {
                            console.log(data.data[i].imageForWebUrl)
                            setRightBanners(data.data[i])
                        }

                        if (data.data[i].isVisible && data.data[i].position === 'ThirdLeftBanner') {
                            console.log(data.data[i].imageForWebUrl)
                            setLeftBanners(data.data[i])
                        }

                        if (data.data[i].isVisible && data.data[i].position === 'LeftOfEndBanner') {
                            console.log(data.data[i])
                            setEndLeftBanner(data.data[i])
                        }

                        if (data.data[i].isVisible && data.data[i].position === 'RightOfEndBanner') {
                            console.log(data.data[i])
                            setEndRightBanner(data.data[i])
                        }

                    }


                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.error('Error fetching masters data:', error);
            });
    }, [apiKey, apiUrl])

    useEffect(() => {
        fetch(`${apiUrl}/testimonial/getAll`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    setTestimonial(data.data);
                } else {
                    console.error('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.log('Error fetching masters data:', error);
            });
    }, [apiKey])

    useEffect(() => {
        async function getUser() {
            const url = `${apiUrl}/employee/verify-customer`;


            let response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setUser(response.data)
            }
        }
        if (Token) {
            getUser();
        }
    }, []);

    useEffect(() => {
        fetch(`${apiUrl}/property/property?limit=3&purpose=${purpose}`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data && Array.isArray(data.data)) {
                    setPropertyList(data.data)
                    console.log(data.data)
                    let count = []
                    for (let j = 0; j < data.data.length; j++) {
                        let temp = 0;
                        for (let i = 0; i < 5; i++) {
                            if (data.data[j][`photo${i + 1}`]) {
                                temp++;
                            }
                        }
                        count = [...count, temp]
                    }
                    console.log(count, 'count=======')
                    setPhotoCount(count)
                } else {
                    console.log('API response does not contain an array:', data);
                }
            })
            .catch(error => {
                console.log('Error fetching masters data:', error);
            });
    }, [apiKey, apiUrl, purpose])

    // A function to handle the submit of the property form
    const handleWishlist = async (e, id, userId) => {
        e.preventDefault();
        e.stopPropagation()
        e.stopPropagation();
        if (!userId) {
            toast.error("Login First")
            return
        }

        try {
            const response = await fetch(`${apiUrl}/property/addToWishlist/${userId}-${id}`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                }
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2500)
        } catch (error) {
            toast.error(error.message);
        }
    };

    // A function to handle the submit of the property form
    const handleCompare = async (e, id, userId) => {
        e.preventDefault();
        e.stopPropagation()
        e.stopPropagation();
        if (!userId) {
            toast.error("Login First")
            return
        }

        try {
            const response = await fetch(`${apiUrl}/property/addToCompare/${userId}-${id}`, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                }
            });

            const response2 = await response.json();
            if (response2.status === "error") {
                throw new Error(response2.message);
            }
            toast.success(response2.message);
            setTimeout(() => {
                window.location.reload()
            }, 2500)
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        async function getWishlist() {
            const url = `${apiUrl}/property/getWishlist/${user.id}`;


            let response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

            if (response.status !== "error") {
                setWishlist(response.wishlistProperty)
                if (Array.isArray(response.wishlistProperty)) {
                    console.log("wishlistProperty is an array");
                } else {
                    console.log("wishlistProperty is not an array");
                }
            }
        }
        if (user?.id) {
            getWishlist()
        }

    }, [user, apiUrl, Token]);



    return (
        <div>
            {/*=============== css  ===============*/}
            <link type="text/css" rel="stylesheet" href="css/plugins.css" />
            <link type="text/css" rel="stylesheet" href="css/style.css" />
            <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
            <link type="text/css" rel="stylesheet" href="css/color.css" />
            {/* main */}
            <div>
                {/* wrapper  */}
                <div id="wrapper" className="p-0">
                    {/* content */}
                    <div className="content">
                        {/*  section  */}
                        <HeroSection />
                        {/*  section  end*/}
                        {/* section */}
                        <section>
                            <div className="container">
                                {/*about-wrap */}
                                <div className="about-wrap">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="about-title ab-hero fl-wrap">
                                                <h2>ADVERTISE YOUR PROPERTY FOR FREE </h2>
                                                <h4>
                                                    Check video presentation to find out more about us .
                                                </h4>
                                            </div>
                                            <div className="services-opions fl-wrap">
                                                <ul>
                                                    <li>
                                                        <i className="fa-solid  fa-headset" />
                                                        <h4>24 Hours Support</h4>
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <i className="fa-solid  fa-users-cog" />
                                                        <h4>User Admin Panel</h4>
                                                        <p>
                                                            Nulla posuere sapien vitae lectus suscipit, et
                                                            pulvinar nisi tincidunt. Curabitur convallis fringilla
                                                            diam sed aliquam.{" "}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <i className="fa-solid fa-mobile-screen" />
                                                        <h4>Mobile Friendly</h4>
                                                        <p>
                                                            Curabitur convallis fringilla diam sed aliquam. Sed
                                                            tempor iaculis massa faucibus feugiat. In fermentum
                                                            facilisis massa.
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-1" />
                                        <div className="col-md-6">
                                            {Object.keys(rightBanner).length > 0 ?
                                                <div className="about-img fl-wrap">
                                                    <img src={rightBanner.imageForWebUrl} className="respimg" alt='image' />
                                                    <div className="about-img-hotifer color-bg">
                                                        <p>
                                                            {rightBanner.imageDescription}
                                                        </p>
                                                        <h4>{rightBanner.imageTitle}</h4>
                                                    </div>
                                                </div>
                                                :
                                                <div className="about-img fl-wrap">
                                                    <img src="images/all/27.jpg" className="respimg" alt="image" />
                                                    <div className="about-img-hotifer color-bg">
                                                        <p>
                                                            Your website is fully responsive so visitors can view
                                                            your content from their choice of device.
                                                        </p>
                                                        <h4>Mark Antony</h4>
                                                        <h5>Webkype CEO</h5>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* about-wrap end  */}
                            </div>
                        </section>
                        {/* section end*/}
                        <DiscoverCities />
                        {/*section end*/}
                        <section>
                            <div className="container">
                                {/*about-wrap */}
                                <div className="about-wrap">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {Object.keys(leftBanner).length > 0 ?
                                                <div className="about-img fl-wrap">
                                                    <img src={leftBanner.imageForWebUrl} className="respimg" alt='image' />
                                                    <div className="about-img-hotifer color-bg">
                                                        <p>
                                                            {leftBanner.imageDescription}
                                                        </p>
                                                        <h4>{leftBanner.imageTitle}</h4>
                                                    </div>
                                                </div>
                                                :
                                                <div className="about-img fl-wrap">
                                                    <img src="images/all/27.jpg" className="respimg" alt="image" />
                                                    <div className="about-img-hotifer color-bg">
                                                        <p>
                                                            Your website is fully responsive so visitors can view
                                                            your content from their choice of device.
                                                        </p>
                                                        <h4>Mark Antony</h4>
                                                        <h5>Webkype CEO</h5>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-1" />
                                        <div className="col-md-5">
                                            <div className="about-title ab-hero fl-wrap">
                                                <h2>ADVERTISE YOUR PROPERTY FOR FREE </h2>
                                                <h4>
                                                    Check video presentation to find out more about us .
                                                </h4>
                                            </div>
                                            <div className="services-opions fl-wrap">
                                                <ul>
                                                    <li>
                                                        <i className="fa-solid  fa-headset" />
                                                        <h4>24 Hours Support</h4>
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing
                                                            elit, sed do eiusmod tempor incididunt ut labore et
                                                            dolore magna aliqua.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <i className="fa-solid  fa-users-cog" />
                                                        <h4>User Admin Panel</h4>
                                                        <p>
                                                            Nulla posuere sapien vitae lectus suscipit, et
                                                            pulvinar nisi tincidunt. Curabitur convallis fringilla
                                                            diam sed aliquam.{" "}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <i className="fa-solid fa-mobile-screen" />
                                                        <h4>Mobile Friendly</h4>
                                                        <p>
                                                            Curabitur convallis fringilla diam sed aliquam. Sed
                                                            tempor iaculis massa faucibus feugiat. In fermentum
                                                            facilisis massa.
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* about-wrap end  */}
                            </div>
                        </section>

                        <section className="small-padding mt-4" style={{ background: 'black' }}>
                            <div className="container">
                                {/*about-wrap */}
                                <div className="about-wrap">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {Object.keys(endLeftBanner).length > 0 ?
                                                <div className="about-img fl-wrap">
                                                    <img src={endLeftBanner.imageForWebUrl} className="respimg" alt='image' />
                                                    <div className="about-img-hotifer color-bg">
                                                        <p>
                                                            {endLeftBanner.imageDescription}
                                                        </p>
                                                        <h4>{endLeftBanner.imageTitle}</h4>
                                                    </div>
                                                </div>
                                                :
                                                <div className="about-img fl-wrap">
                                                    <img src="images/all/27.jpg" className="respimg" alt="image" />
                                                    <div className="about-img-hotifer color-bg">
                                                        <p>
                                                            Your website is fully responsive so visitors can view
                                                            your content from their choice of device.
                                                        </p>
                                                        <h4>Mark Antony</h4>
                                                        <h5>Webkype CEO</h5>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                        <div className="col-md-6">
                                            {console.log('endRightBanner =>', endRightBanner)}
                                            {Object.keys(endRightBanner).length > 0 ?
                                                <div className="about-img fl-wrap">
                                                    <img src={endRightBanner.imageForWebUrl} className="respimg" alt='image' />
                                                    <div className="about-img-hotifer color-bg">
                                                        <p>
                                                            {endRightBanner.imageDescription}
                                                        </p>
                                                        <h4>{endRightBanner.imageTitle}</h4>
                                                    </div>
                                                </div>
                                                :
                                                <div className="about-img fl-wrap">
                                                    <img src="images/all/27.jpg" className="respimg" alt="image" />
                                                    <div className="about-img-hotifer color-bg">
                                                        <p>
                                                            Your website is fully responsive so visitors can view
                                                            your content from their choice of device.
                                                        </p>
                                                        <h4>Mark Antony</h4>
                                                        <h5>Webkype CEO</h5>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div className="pwh_bg" />
                                </div>
                                {/* about-wrap end  */}
                            </div>
                        </section>
                        {/* section */}
                        <section className="gray-bg small-padding">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="section-title fl-wrap">
                                            <h4>Browse Hot Offers</h4>
                                            <h2>Latest Properties</h2>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="listing-filters gallery-filters">
                                            <a
                                                href="#1"
                                                className="gallery-filter gallery-filter-active"
                                                data-filter="*"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPurpose('')
                                                }}
                                            >
                                                {" "}
                                                <span>All Categories</span>
                                            </a>
                                            <a
                                                href="#sale"
                                                className="gallery-filter"
                                                data-filter=".for_sale"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPurpose('wantToSell')
                                                }}
                                            >
                                                {" "}
                                                <span>For Sale</span>
                                            </a>
                                            <a
                                                href="#rent"
                                                className="gallery-filter"
                                                data-filter=".for_rent"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setPurpose('wantToRent')
                                                }}
                                            >
                                                {" "}
                                                <span>For Rent</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix" />
                                {/* grid-item-holder*/}
                                <div
                                    className="grid-item-holder gallery-items gisp fl-wrap"
                                >
                                    {/* gallery-item*/}
                                    {propertyList.length === 0 &&
                                        <>
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                        </>
                                    }
                                    {propertyList.map((prop, i) => (
                                        <div
                                            key={prop.slug}
                                            className={`gallery-item for_${prop.purpose !== 'wantToRent' ? 'sale' : 'rent'}`}
                                            onClick={() => {
                                                window.location.href = prop.slug
                                            }}
                                            role="button"
                                        >
                                            <article className="geodir-category-listing fl-wrap" >
                                                <div className="geodir-category-img fl-wrap">
                                                    <div
                                                        className="geodir-category-img_item"
                                                        style={{ padding: '2.5px' }}
                                                    >
                                                        <p style={{ paddingBottom: '2px' }}>
                                                            <img src={`${apiUrl}/uploads/${prop.photo1 ? prop.photo1 : prop.photo2}`} alt="property" style={{ height: '150px' }} />
                                                            {/* <div className="overlay" /> */}
                                                        </p>
                                                        <div className='geodir-category-img_item' style={{ display: 'flex', alignItems: 'center' }}>
                                                            <p style={{ paddingRight: '1px', paddingBottom: "0px" }}>
                                                                <img src={`${apiUrl}/uploads/${prop.photo2 ? prop.photo2 : prop.photo3}`} alt="property" style={{ height: '150px', width: '188.5px' }} />
                                                                {/* <div className="overlay" /> */}
                                                            </p>
                                                            <p style={{ paddingLeft: '1px', paddingBottom: "0px" }}>
                                                                <img src={`${apiUrl}/uploads/${prop.photo3 ? prop.photo3 : prop.photo4}`} alt="property" style={{ height: '150px', width: '188.5px' }} />
                                                                {/* <div className="overlay" /> */}
                                                            </p>
                                                        </div>

                                                    </div>

                                                    {/* <p
                                                        className="geodir-category-img_item"
                                                        style={{ padding: '5px' }}
                                                    >
                                                        <img src={`${apiUrl}/uploads/${prop.photo1 ? prop.photo1 : prop.photo2}`} alt="property" style={{ height: '250px' }} />
                                                        <div className="overlay" />
                                                    </p> */}

                                                    <div className="geodir-category-location">
                                                        <a
                                                            href="#1"
                                                            className="map-item tolt"
                                                            data-microtip-position="top-left"
                                                            data-tooltip="On the map"
                                                        >
                                                            <FontAwesomeIcon icon="fa-solid  fa-map-marker-alt" /> {prop.cities.name} , {prop.states.name}
                                                        </a>
                                                    </div>
                                                    <ul className="list-single-opt_header_cat">
                                                        {prop.purpose === 'bothSellRent' &&
                                                            <li>

                                                                <a href="#2" className="cat-opt blue-bg">
                                                                    Rent & Sale
                                                                </a>
                                                            </li>
                                                        }
                                                        {prop.purpose !== 'bothSellRent' &&
                                                            <li>

                                                                <a href="#2" className="cat-opt blue-bg">
                                                                    {prop.purpose !== 'wantToRent' ? 'Sale' : 'Rent'}
                                                                </a>
                                                            </li>
                                                        }


                                                        {/* <li>
                                                            <a href="#3" className="cat-opt color-bg">
                                                                {prop.type}
                                                            </a>
                                                        </li> */}
                                                        <li>
                                                            <a href="#" className="cat-opt color-bg">
                                                                {prop.category}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <p
                                                        className={`geodir_save-btn tolt text-center ${wishlist?.find((item) => item === prop.id) ? "text-danger" : ""}`}
                                                        data-microtip-position="left"
                                                        data-tooltip="Save"
                                                        onClick={(e) => {
                                                            handleWishlist(e, prop.id, user.id)
                                                        }}
                                                    >
                                                        <span>
                                                            <FontAwesomeIcon icon="fa-solid fa-heart" />
                                                        </span>
                                                    </p>
                                                    <p
                                                        className="compare-btn tolt text-center"
                                                        data-microtip-position="left"
                                                        data-tooltip="Compare"
                                                        onClick={(e) => {
                                                            handleCompare(e, prop.id, user.id)
                                                        }}
                                                    >
                                                        <span>
                                                            <FontAwesomeIcon icon="fa-solid  fa-random" />
                                                        </span>
                                                    </p>

                                                    <div className="geodir-category-listing_media-list">
                                                        <span>
                                                            <FontAwesomeIcon icon="fa-solid  fa-camera" /> {photoCount[i]}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="geodir-category-content fl-wrap"
                                                    role='button'
                                                >
                                                    <h3>
                                                        <a>{
                                                            prop.name ? <>{prop.name.slice(0, 69)}</> :
                                                                <>Gorgeous house for sale</>
                                                        }
                                                        </a>
                                                    </h3>
                                                    <div className="geodir-category-content_price">$ {prop.maxPrice}</div>

                                                    {
                                                        prop.description ?
                                                            <p>{prop.description.slice(0, 40)}...</p> :
                                                            <p>
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar.
                                                                Donec a consectetur nulla.
                                                            </p>
                                                    }
                                                    <div className="geodir-category-content-details">
                                                        <ul>
                                                            <li>
                                                                <FontAwesomeIcon icon="fa-solid  fa-bed" />
                                                                <span>{" "}{prop?.listingDetails?.Bedrooms}</span>
                                                            </li>
                                                            <li>
                                                                <FontAwesomeIcon icon="fa-solid  fa-bath" />
                                                                <span>{" "}{prop?.listingDetails?.Bathroom}</span>
                                                            </li>
                                                            <li>
                                                                <FontAwesomeIcon icon="fa-solid  fa-cube" />
                                                                <span>{" "}{prop.maxSize} Sq Ft</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="geodir-category-footer fl-wrap"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >

                                                        <a href={`${prop.slug}`} className="gcf-company">
                                                            <img src={prop?.Customer?.userDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : (prop?.Customer?.agentDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agentDetails[0]?.photo}` : (prop?.Customer?.agencyDetails.length > 0 ? `${apiUrl}/uploads/${prop?.Customer?.agencyDetails[0]?.photo}` : "images/avatar/2.jpg"))} alt="poster" style={{ objectFit: 'cover' }} />
                                                            {/* <img src={prop?.Customer?.userDetails ? `${apiUrl}/uploads/${prop?.Customer?.userDetails[0]?.photo}` : "images/avatar/2.jpg"} alt="poster" /> */}
                                                            <span>By {prop.postedBy}</span>
                                                        </a>
                                                        <a
                                                            href={`${prop.slug}`}
                                                            style={{
                                                                display: "flex",
                                                                gap: "10px"
                                                            }}>
                                                            <i className="fa-solid fa-phone" />
                                                            <i className="fa-brands fa-whatsapp" />
                                                        </a>

                                                        {/* <div
                                                            className="listing-rating card-popup-rainingvis"
                                                            data-microtip-position="top"
                                                            data-tooltip="Good"
                                                            data-starrating2={4}
                                                        /> */}
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    ))}
                                    <div className="gallery-item for_sale">

                                    </div>
                                    {/* gallery-item end*/}
                                    {/* gallery-item*/}
                                    <div className="gallery-item for_sale">

                                    </div>
                                    {/* gallery-item end*/}
                                    {/* gallery-item*/}
                                    <div className="gallery-item for_sale">

                                    </div>
                                    {/* gallery-item end*/}
                                    {/* gallery-item*/}
                                    <div className="gallery-item for_sale for_rent">

                                    </div>
                                    {/* gallery-item end*/}
                                    {/* gallery-item*/}
                                    <div className="gallery-item for_rent">

                                    </div>
                                    {/* gallery-item end*/}
                                </div>
                                {/* grid-item-holder*/}
                                <button className="btn float-btn text-white color-bg" onClick={(e) => {
                                    e.preventDefault()
                                    window.location.href = '/listing'
                                }}>
                                    View All Properties
                                </button>
                            </div>
                        </section>
                        {/* section end*/}
                        {/* section */}
                        <section className="color-bg small-padding">
                            <div className="container">
                                <div className="main-facts fl-wrap" >
                                    {/* inline-facts  */}
                                    <div className="inline-facts-wrap">
                                        <div className="inline-facts">
                                            <div className="milestone-counter">
                                                <div className="stats animaper">
                                                    <div className="num" data-content={0} data-num={578} style={{ color: 'black' }}>
                                                        0
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 style={{ color: 'black' }}>Agents and Agencys</h6>
                                        </div>
                                    </div>
                                    {/* inline-facts end */}
                                    {/* inline-facts  */}
                                    <div className="inline-facts-wrap">
                                        <div className="inline-facts">
                                            <div className="milestone-counter">
                                                <div className="stats animaper">
                                                    <div className="num" data-content={0} data-num={12168} style={{ color: 'black' }}>
                                                        0
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 style={{ color: 'black' }}>Happy Customers Every Year</h6>
                                        </div>
                                    </div>
                                    {/* inline-facts end */}
                                    {/* inline-facts  */}
                                    <div className="inline-facts-wrap">
                                        <div className="inline-facts">
                                            <div className="milestone-counter">
                                                <div className="stats animaper">
                                                    <div className="num" data-content={0} data-num={2172} style={{ color: 'black' }}>
                                                        0
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 style={{ color: 'black' }}>Won Awards</h6>
                                        </div>
                                    </div>
                                    {/* inline-facts end */}
                                    {/* inline-facts  */}
                                    <div className="inline-facts-wrap">
                                        <div className="inline-facts">
                                            <div className="milestone-counter">
                                                <div className="stats animaper">
                                                    <div className="num" data-content={0} data-num={732} style={{ color: 'black' }}>
                                                        0
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 style={{ color: 'black' }}>New Listing Every Week</h6>
                                        </div>
                                    </div>
                                    {/* inline-facts end */}
                                </div>
                            </div>
                            <div className="svg-bg">
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    width="100%"
                                    height="100%"
                                    viewBox="0 0 1600 900"
                                    preserveAspectRatio="xMidYMax slice"
                                >
                                    <defs>
                                        <linearGradient id="bg">
                                            <stop
                                                offset="0%"
                                                style={{ stopColor: "rgba(255, 255, 255, 0.6)" }}
                                            />
                                            <stop
                                                offset="50%"
                                                style={{ stopColor: "rgba(255, 255, 255, 0.1)" }}
                                            />
                                            <stop
                                                offset="100%"
                                                style={{ stopColor: "rgba(255, 255, 255, 0.6)" }}
                                            />
                                        </linearGradient>
                                        <path
                                            id="wave"
                                            stroke="url(#bg)"
                                            fill="none"
                                            d="M-363.852,502.589c0,0,236.988-41.997,505.475,0
                                  s371.981,38.998,575.971,0s293.985-39.278,505.474,5.859s493.475,48.368,716.963-4.995v560.106H-363.852V502.589z"
                                        />
                                    </defs>
                                    <g>
                                        <use xlinkHref="#wave">
                                            <animateTransform
                                                attributeName="transform"
                                                attributeType="XML"
                                                type="translate"
                                                dur="10s"
                                                calcMode="spline"
                                                values="270 230; -334 180; 270 230"
                                                keyTimes="0; .5; 1"
                                                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                                                repeatCount="indefinite"
                                            />
                                        </use>
                                        <use xlinkHref="#wave">
                                            <animateTransform
                                                attributeName="transform"
                                                attributeType="XML"
                                                type="translate"
                                                dur="8s"
                                                calcMode="spline"
                                                values="-270 230;243 220;-270 230"
                                                keyTimes="0; .6; 1"
                                                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                                                repeatCount="indefinite"
                                            />
                                        </use>
                                        <use xlinkHref="#wave">
                                            <animateTransform
                                                attributeName="transform"
                                                attributeType="XML"
                                                type="translate"
                                                dur="6s"
                                                calcMode="spline"
                                                values="0 230;-140 200;0 230"
                                                keyTimes="0; .4; 1"
                                                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                                                repeatCount="indefinite"
                                            />
                                        </use>
                                        <use xlinkHref="#wave">
                                            <animateTransform
                                                attributeName="transform"
                                                attributeType="XML"
                                                type="translate"
                                                dur="12s"
                                                calcMode="spline"
                                                values="0 240;140 200;0 230"
                                                keyTimes="0; .4; 1"
                                                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                                                repeatCount="indefinite"
                                            />
                                        </use>
                                    </g>
                                </svg>
                            </div>
                        </section>
                        {/* section end*/}
                        {/* section */}
                        <section className="gray-bg ">
                            <div className="container">
                                <div className="section-title st-center fl-wrap">
                                    <h4>Testimonilas</h4>
                                    <h2>What Our Clients Say</h2>
                                </div>
                            </div>
                            <div className="clearfix" />

                            <div className="custom-slider-testimonial">
                                {testimonial?.length ?
                                    <Testimonial testimonial={testimonial} />
                                    : <>
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </Button>
                                    </>}
                            </div>
                        </section>
                        {/* section end*/}
                    </div>

                    <Footer />

                </div>

            </div>

        </div>
    );
}

export default Home;
